import React, { useState, useEffect, useContext } from 'react';
import { Web3Context } from 'contexts';
import * as scRequests from '../../../contexts/scRequests';
import { usePlaceBid } from 'contexts/hooks/transactions/usePlaceBid';
import { useClaimReward } from 'contexts/hooks/transactions/useClaimReward';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Address } from '@multiversx/sdk-core/out';
import styles from '../testRoute.module.scss';
import axios from 'axios';
import * as config from '../../../config';
import { decimalToHex } from 'api/getAuctionInfo';
import { Loader } from '@multiversx/sdk-dapp/UI/Loader/Loader';
import {useGetPendingTransactions} from '@multiversx/sdk-dapp/hooks';


const Timer: React.FC<{ duration: number; nextPossibleBid: number, tokenId: string, auctionInfo: any}> = ({
  duration,
  nextPossibleBid,
  tokenId,
  auctionInfo
}) => {
  const [seconds, setSeconds] = useState(duration);
  const { address } = useGetAccountInfo();  
  const auctionSettings = useSelector(
    (state: RootState) => state.auctionInfo.auctionSettings
  );
  const { isInitialLoadDone, currentAuctionId } = useContext(Web3Context);

  const placeBid = usePlaceBid(nextPossibleBid, tokenId); //next possible bid here
  const claimAsset = useClaimReward(currentAuctionId);

  //get loading transactions for sending to elements
	const loadingTransactions = useGetPendingTransactions().hasPendingTransactions;

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [seconds]);

  useEffect(() => {
    setSeconds(duration);
  }, [duration]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(Math.trunc(seconds)).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const formatTimeVar = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(Math.trunc(seconds)).padStart(2, '0');

    return (
      <div className={styles.dates}>
        <div className={styles.date}>
          <div className={styles.dateTitle}>{formattedMinutes}</div>
          <div className={styles.dateText}>Min</div>
        </div>
        <div className={styles.date}>
          <div className={styles.dateTitle}>{formattedSeconds}</div>
          <div className={styles.dateText}>Seconds</div>
        </div>
      </div>
    );
  };
  
  return  auctionInfo === null ||
    auctionInfo === undefined ||
    auctionSettings.status === 'failed' ||
    !isInitialLoadDone ||
    auctionSettings.data === null ? (
      <>
      {/* <Loader /> */}
      </>
    ) : (
    <>
      {seconds <= 0 && (
        <>
          {new Address(address).hex() === auctionInfo.last_bidder ? (
            <>
              <div className={styles.timerDiv}>
                <div className={styles.balanceText}>
                  Auction ended
                </div>

                <div className='d-flex justify-content-center flex-column align-items-center mb-3'>


                  <div className={styles.dateTitle}>You are the winner</div>
                  <div className='mb-3'></div>
                  {auctionInfo.claimed !== 1 ? (
                    <div onClick={claimAsset} className={address && !loadingTransactions ? (`${styles.bidBtn}`) : (`${styles.bidBtn} ${styles.disabled}`)}>
                      Claim
                    </div>
                  ) : (
                    <div className={styles.balanceText}>
                      Claimed
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.timerDiv}>
                <div className={styles.bided}>
                  Auction ended
                </div>
              </div>
            </>
          )}
        </>
      )}
      {seconds > 0 && (
        <>
          {auctionSettings.data.is_auction_open && (
            <>
              <div className='d-flex justify-content-center flex-column align-items-center'>
                <div className={styles.balanceText}>Auction ends in</div>
                <div className=''>{formatTimeVar(seconds)}</div>
                <div 
                  onClick={placeBid} 
                  className={address && !loadingTransactions ? (`${styles.bidBtn}`) : (`${styles.bidBtn} ${styles.disabled}`)}
                  style={{marginTop: '-25px'}}
                >
                  Place bid
                </div>
                {new Address(address).hex() === auctionInfo.last_bidder && (
                  <div className={`${styles.dateTitle} mt-1`}>You are the last bidder</div>
                )}
              </div>
            </>
          )}

          {!auctionSettings.data.is_auction_open && (
            <>
              {new Address(address).hex() === auctionInfo.last_bidder ? (
                <>
                  <div className={styles.timerDiv}>
                    <div className={styles.balanceText}>
                      Auction ended
                    </div>

                    <div className='d-flex justify-content-center flex-column align-items-center mb-3'>


                      <div className={styles.dateTitle}>You are the winner</div>
                      <div className='mb-3'></div>
                      {auctionInfo.claimed !== 1 ? (
                        <div onClick={claimAsset} className={styles.bidBtn}>
                          Claim
                        </div>
                      ) : (
                        <div className={styles.balanceText}>
                          Claimed
                        </div>
                      )}

                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.timerDiv}>
                    <div className={styles.bided}>
                      Auction ended
                    </div>
                  </div>
                </>
              )}

            </>
          )}

        </>
      )}
    </>
  );
};

export default Timer;


  // const getScNftBalance = async () => {
  //   console.log('get sc nft balance');
  //   // https://api.multiversx.com/accounts/erd17n4gpw7gkjy32fkq2nupqapluqmfhfwyc8kkm6ztjxg9qxdnesgq822ftt/nfts/XLHO-5135c9-0c91

  //   try {
  //     const { data } = await axios.get(
  //       `${config.network.apiAddress}/accounts/${config.contractAddress}/nfts/${auctionInfo.data?.asset?.token_identifier}-${decimalToHex(auctionInfo.data?.asset?.nonce)}`,
  //       {
  //         timeout: 10000
  //       }
  //     );

  //     console.log(data);
  //     return {
  //       data: data,
  //       success: data !== undefined
  //     };
  //   } catch (err) {
  //     return {
  //       data: '0',
  //       success: true
  //     };
  //   }


  // };

  // useEffect(() => {
  //   //get sc nft balance
  //   getScNftBalance().then((data) => {
  //     if (data.data !== '0') {
  //       setIsOnTheSc(true);
  //     }
  //   });
  //   console.log(isOnTheSC);
  // }, []);