import { NonFungibleToken } from './nonFungibleToken';

export interface AuctionInfoAndUrl {
    asset: NonFungibleToken | undefined;
    starting_price: string;
    last_bid_price: string | undefined;
    last_bid_timestamp: number;
    last_bid_block: number;
    start_time: number;
    end_time: number;
    last_bidder: string;
    url: string[],
    name: string,
    claimed: number,
    esdt_token_id: string,
    esdt_token_name: string
  }
  //add name


  export const DEFAULT_URL: AuctionInfoAndUrl = {
    asset: undefined,
    starting_price: '0',
    last_bid_price: '0',
    last_bid_timestamp: 0,
    last_bid_block: 0,
    start_time: 0,
    end_time: 0,
    last_bidder: '',
    url: [],
    name: '',
    claimed: 0,
    esdt_token_id: '',
    esdt_token_name: ''
  };