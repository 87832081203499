import React from 'react';
import styles from './layout.module.scss';
import { useLocation } from 'react-router-dom';
import { AuthenticatedRoutesWrapper } from 'components';
import { routes, routeNames } from 'routes';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  return (
    <div className={styles.container}>
    <div className={styles.blur1} />
    <div className={styles.blur2} />
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};
