import React, { useState, useEffect, useContext } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { useMysteryAddReward } from 'contexts/hooks/transactions/useMysteryAddReward';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from 'react-bootstrap/Card';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import * as XLSX from 'xlsx';

export const UpdatePoints = () => {
  const { updateReferralPoints, getFullUserByWalletAddress } = useContext(UserContext);

  const [wallet, setWallet] = useState('');
  const [points, setPoints] = useState(1);
  const handleUpdatePoints = async () => {
    await getFullUserByWalletAddress(wallet).then(async (r: any) => {
      const uid = r.uid;
      const updated = await updateReferralPoints(uid, points);
      if(updated){
        toast.success(`${points} points added to wallet`, {duration: 3000});   
      }else{
        toast.error('Failed to add points', {duration: 3000});   
      }
      console.log('updated: ' + updated);
    });
  };

  return (
    <>
      <Card bg='dark'>
        <Card.Header>
          <span className='text-capitalize'>Update user points by wallet</span>
        </Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-between align-items-baseline'>
            <p>Wallet:</p>
            <Input
              size="small"
              type="text" 
              className="text-white font-bold px-2 pt-1 b-r-xs ms-2"
              style={{border: '0.5px solid rgb(74, 85, 104)', width: '100%', textAlign: 'center', fontSize: '13px'}}
              value={wallet}
              onChange={e => setWallet(e.target.value)}
              disableUnderline
              inputProps={{
                noValidate: true,
              }}
            />
          </div>
          <div className='d-flex justify-content-between align-items-baseline'>
            <p>Points: </p>
            <Input
              size="small"
              type="number" 
              className="text-white font-bold px-2 pt-1 b-r-xs ms-2"
              style={{border: '0.5px solid rgb(74, 85, 104)', width: '100%', textAlign: 'center', fontSize: '13px'}}
              value={points}
              onChange={e => setPoints(Number(e.target.value))}
              disableUnderline
            />
          </div>
        </Card.Body>
        <Card.Footer>
          <div className='d-flex justify-content-end align-items-baseline'>                           
            <Button size='small' fullWidth className='btn-info ms-2' color='inherit' variant='contained'  onClick={() => handleUpdatePoints()}>
              Add Points
            </Button>
          </div>
        </Card.Footer>
      </Card>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </>
  );
};
