import * as React from 'react';
import styles from './faq.module.scss';
// import { Interface } from 'readline';

const FaqElement: React.FC<{ title: string, description: string, showFaq: any, setShowFaq: any }> = ({ title, description, showFaq, setShowFaq }) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const textRef = React.useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = React.useState<number | undefined>(0);

    // THIS USEFFECT IS USED TO GET DIV HEIGHT
    React.useEffect(() => {
        setHeight(textRef.current?.scrollHeight);
        window.addEventListener('resize', () => {
            setHeight(textRef.current?.scrollHeight);
        });
        return () => {
            window.removeEventListener('resize', () => {
                //
            });
        };
    }, [window.innerWidth]);

    return (
        <main className={`${styles.faq} ${showFaq === title ? styles.show : ''}`}>
            <div
                onClick={() => {
                    showFaq !== title ? setShowFaq(title) : setShowFaq('');
                }}
                style={{ cursor: 'pointer' }}
                className="d-flex justify-content-between align-items-center"
            >
                <div
                    className={`${styles.faqTitle} ${showFaq === title ? styles.show : ''
                        }`}
                >
                    {title}
                </div>
                {showFaq === title ? (
                    <img
                        onClick={() => {
                            showFaq !== title ? setShowFaq(title) : setShowFaq('');
                        }}
                        src="/media/faqs/Line 3.svg"
                        alt=''
                        style={{ cursor: 'pointer' }}
                    />
                ) : (
                    <img
                        onClick={() => {
                            showFaq !== title ? setShowFaq(title) : setShowFaq('');
                        }}
                        src="/media/faqs/download (5) 1.svg"
                        alt=''
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </div>

            <div
                ref={ref}
                style={{
                    height: showFaq === title ? `${height ?? 100 + 10}px` : '0px',
                    paddingTop: showFaq === title ? '' : '0px',
                }}
                className={styles.description}
            >
                <img
                    style={{ height: `${height ?? 100}px` }}
                    src="/media/faqs/Line 4.svg"
                    alt=''
                    className=''
                />
                <div ref={textRef} className='' style={{ height: 'fit-content' }}>{description}</div>
            </div>
        </main>
    );
};

export default FaqElement;