import React, { useContext } from 'react';
import * as config from '../../config';
import axios from 'axios';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';

export const useGetTokenData = () => { //when there's no balance of token this function returns error: not found instead of 0
    const context = useContext(Web3LotteryContext);
    const getTokenData = async () => {

        if (context.rewardTokenId !== undefined && context.rewardTokenId !== null) {
            const requestLink = `${config.network.apiAddress}/tokens/${context.rewardTokenId}`;

            try {
                const { data } = await axios.get(
                    requestLink,
                    {
                        timeout: 20000
                    }
                );

                return data;
            } catch (err) {
                return undefined;
            }
        }
    };
    return getTokenData;
};
