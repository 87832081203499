import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import React, { useState } from 'react';
import * as config from '../../config';
import { Web3Context, Web3Provider } from 'contexts';
import * as scRequests from '../../contexts/scRequests';
import { NftPicker } from '../../components/NftPicker';
import { ContractDetails } from './ContractDetails';
import { AuctionDetails } from './AuctionDetails';
import { TransactionsCard } from './TransactionsCard';
import { Layout } from 'components/Layout/Layout';

export const Admin = () => {
  const { address } = useGetAccount();
  const [inputText, setInputText] = React.useState('');
  const [inputTokenId, setInputTokenId] = React.useState('');
  const [inputNonce, setInputNonce] = React.useState('');
  const [inputAmount, setInputAmount] = React.useState('');

  const [showNftModalPicker, setShowNftModalPicker] = useState(false);
  const [selectedNft, setSelectedNft] = useState<any>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleChangeTokenId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTokenId(e.target.value);
  };

  const handleChangeNonce = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputNonce(e.target.value);
  };

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputAmount(e.target.value);
  };

  const handleChooseNftAsset = () => {
    setShowNftModalPicker(true);
  };

  if(address.toString() === config.adminAddress){
    return (
      <Layout>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1 className='mt-5 mb-5'>
                <strong>Auction admin panel</strong>
              </h1>
            </div>

            <div className='col-lg-6 col-sm-12 mb-5'>
              <ContractDetails />
            </div>

            <div className='col-lg-6 col-sm-12'>
              <AuctionDetails />
            </div>

            <div className='col-12 mt-3'>
              <TransactionsCard />
            </div>
          </div>
        </div>
      </Layout>
    );
  }else{
    return (
      <Layout>
        <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>Page visible to admin only</p>
      </Layout>
    );
  }
};
