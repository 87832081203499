import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import React, { useState } from 'react';
import * as config from '../../config';
import * as scRequests from '../../contexts/scMysteryPrizesRequests';
import { Layout } from 'components/Layout/Layout';
import { KeysContractDetails } from './KeysContractDetails';
import { PrizesContractDetails } from './PrizesContractDetails';
import { KeysTransactionsCard } from './KeysTransactionsCard';
import { PrizesTransactionsCard } from './PrizesTransactionsCard';

export const AdminMystery = () => {
  const { address } = useGetAccount();
  const [inputText, setInputText] = React.useState('');
  const [inputTokenId, setInputTokenId] = React.useState('');
  const [inputNonce, setInputNonce] = React.useState('');
  const [inputAmount, setInputAmount] = React.useState('');

  if(address.toString() === config.adminAddress){
    return (
      <Layout>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1 className='mt-5 mb-5'>
                <strong>Mystery admin panel</strong>
              </h1>
            </div>

            <div className='col-lg-6 col-sm-12 mb-5'>
              <KeysContractDetails />
            </div>

            <div className='col-lg-6 col-sm-12'>
              <PrizesContractDetails />
            </div>

            <div className='col-12 mt-3'>
              <KeysTransactionsCard />
            </div>

            <div className='col-12 mt-3'>
              <PrizesTransactionsCard />
            </div>
          </div>
        </div>
      </Layout>
    );
  }else{
    return (
      <Layout>
        <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>Page visible to admin only</p>
      </Layout>
    );
  }
};
