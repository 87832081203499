import { Spinner } from 'react-bootstrap';
import './styles.css';

export const LoadingScreen = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div
          className='col-12 d-flex justify-content-center align-items-center'
          style={{ marginTop: '20%' }}
        >
          <Spinner />
        </div>
        <div className='col-12 d-flex justify-content-center align-items-center mt-3 loadingScreen'>
          <h3>Loading..</h3>
        </div>
      </div>
    </div>
  );
};
