import { faBan, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { PageState } from '@multiversx/sdk-dapp/UI/PageState/PageState';
import { TransactionsTable } from '@multiversx/sdk-dapp/UI/TransactionsTable/TransactionsTable';
import { getTransactions } from '@multiversx/sdk-dapp/apiCalls/transactions/getTransactions';
import { ServerTransactionType } from '@multiversx/sdk-dapp/types';
import React, { useEffect, useState } from 'react';
import * as config from '../../config';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks/useGetNetworkConfig';
import { AxiosError } from 'axios';
import { LoadingScreen } from 'components/LoadingScreen';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks/transactions/useGetActiveTransactionsStatus';
import { apiTimeout, contractAddress, transactionSize } from 'config';

export const TransactionsCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [transactions, setTransactions] = useState<ServerTransactionType[]>([]);
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const { success, fail } = useGetActiveTransactionsStatus();

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTransactions({
        apiAddress,
        receiver: config.contractAddress,
        condition: 'must',
        transactionSize,
        apiTimeout
      });
      setTransactions(data);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchTransactions();
    }
  }, [success, fail]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div className='my-5'>
        <PageState
          icon={faBan}
          className='text-muted'
          title='Error fetching transactions.'
        />
      </div>
    );
  }

  if (transactions.length === 0) {
    return (
      <div className='my-5'>
        <PageState
          icon={faExchangeAlt}
          className='text-muted'
          title='No Transactions'
        />
      </div>
    );
  }

  return <TransactionsTable transactions={transactions} />;
  // return (
  //   <div className='card'>
  //     <div className='card-header'>Contract transactions</div>
  //     <div className='card-body' style={{ overflowY: 'scroll' }}>
  //       ....
  //     </div>
  //   </div>
  // );
};
