import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Typography } from '@mui/material';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import * as scRequests from '../../../../contexts/scLotteryRequests';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { Address } from '@multiversx/sdk-core/out';
import { WinningNumbers, getCorrectIndex } from '../WinningNumbers/WinningNumbers';
import styles from '../../lottery.module.scss';
import { TicketCardNumber } from '../TicketCard/TicketCardNumber';

const ModalWithCards = () => {
    const [open, setOpen] = useState(false);

    const context = useContext(Web3LotteryContext);
    const { address } = useGetAccountInfo();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // console.log('user tickets are', context.userTickets);
    // console.log('ticket is', context.userTickets[0]);
    // console.log('numbers are', context.userTickets[0].map((number: any) => number));
    // console.log('numbers are', context.userTickets[0].field1);

    // console.log('USER TICKETS NUMBERS ARE', context.userTickets[0]);

    return context.userTickets.length === 0 ||
        context.userTickets === undefined ||
        context.userTickets[0] === undefined ||
        context.userTickets[0].length === 0
        ? (
            <>
                <div className={styles.historyTime} style={{paddingRight: '20px', whiteSpace: 'nowrap'}}> No tickets sent </div>
            </>
        ) : (
            <>
                <a className={styles.buttonLight} onClick={handleOpen}>
                    Your tickets
                </a>
                <Dialog  sx={{ backgroundColor: 'transparent !important', borderRadius: '10px' }} open={open} onClose={handleClose}>
                    <div className={styles.pastWinningModal}>
                        <div className='d-flex flex-column justify-content-start ps-4'>
                            <div className='d-flex flex-row justify-content-between align-items-center me-2'>
                                <h3 className={styles.historyTitleMod2}>Your tickets for this lottery</h3>
                                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
                            </div>
                            <div className={styles.historyTime} >Lottery #{context.currentLotteryId}</div>
                        </div>
                        <DialogContent sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, maxHeight: 400, overflowY: 'auto'}}>

                            {context.userTickets.map((numbers: number[], index: any) => (

                                <div key={index} className='d-flex flex-column justify-content-between w-100' >
                                    <div className={styles.historyTime}>Ticket #{index + 1}</div>
                                    <div key={index} className="d-flex w-100 justify-content-between flex-row gap-3">
                                        {Array.isArray(numbers) &&
                                            (numbers.map((number, i) => (
                                                <div key={i} className={styles.numbersRight}>
                                                    {i % 2 === 0 ? (
                                                        <div className={`text-white font-bold ${getCorrectIndex(i)}`}>
                                                            <div className={styles.dateText}>
                                                                <TicketCardNumber number={number} />
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div className={`text-white font-bold ${getCorrectIndex(i)}`}>
                                                            <div className={styles.dateText}>
                                                                <TicketCardNumber number={number} />
                                                            </div>
                                                        </div>

                                                    )}

                                                </div>

                                            )
                                            )
                                            )
                                        }

                                    </div>
                                </div>
                            ))}

                        </DialogContent>
                        <DialogActions>
                            <a className={`mt-2 ${styles.buttonDark}`} onClick={handleClose}>
                                Close
                            </a>
                        </DialogActions>
                    </div>
                </Dialog>
            </>
        );
};

export default ModalWithCards;

{/* <Dialog open={props.open} onClose={handleOnClose} style={{ borderRadius: '20px ' }}>
    <div className={styles.numbersModal} >
        <div className={styles.historyTitle}>
            <h3>
                Pick your numbers
            </h3>
        </div>
        <DialogContent>
            <div>
                {canSave
                    ? (
                        <>
                            <div className={styles.historyTime}>
                                You have picked 3 numbers.
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div className={styles.historyTime}>
                                Pick {3 - numbers.length} more numbers.
                            </div>
                        </>
                    )}
            </div>
            <Stack direction="row" flexWrap="wrap" gap={2} mt={2}>
                {allPossibleNumbers.map((number, index) => (
                    <PickNumberInput index={index} key={number} value={number} isSelected={numbers.includes(number)} onClick={handleNumberClick} isClickable={!canSave || numbers.includes(number)} />
                ))}
            </Stack>
        </DialogContent>
        <div className={styles.actions}>
            <a className={styles.buttonDark} onClick={handleOnClose} color="error">Cancel</a>
            {numbers.length === 3 && (
                <a className={styles.buttonLight} onClick={handleOnSave} >Confirm</a>
            )}

        </div>
    </div>
</Dialog> */}