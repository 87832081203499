import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { NftCard } from './NftCard';
import { useCreateNewAuction } from 'contexts/hooks/transactions/useCreateNewAuction';

export const NftPicker = ({
  nfts,
  onSelectNft,
  onDismissModal,
  showModal,
  tokenId
}: {
  nfts: any[];
  onSelectNft: (nft: any) => void;
  onDismissModal: () => void;
  showModal: boolean;
  tokenId: string
}) => {
  const [selectedNft, setSelectedNft] = useState();
  const createNewAuction = useCreateNewAuction(selectedNft, tokenId);

  // console.log(selectedNft);
  return (
    <Modal centered show={showModal} size='xl'>
      <Modal.Header>Select NFT</Modal.Header>
      <Modal.Body>
        <div style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
          <div className='container-fluid'>
            <div className='row'>
              {nfts.map((nft, index) => (
                <div className='col-3' key={`nft-picker-modal-${index}`}>
                  <NftCard
                    nft={nft}
                    isSelected={selectedNft === nft}
                    onSelect={() => setSelectedNft(nft)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex w-100 justify-content-between align-items-center'>
        {selectedNft !== undefined ? (
          <>
            <button
              className='btn btn-primary'
              onClick={createNewAuction}
            >
              Create New Auction
            </button>
            <button className='btn btn-danger' onClick={onDismissModal}>
              Dismiss
            </button>
          </>
        ) : (
          <>
            <div></div>
            <button className='btn btn-danger' onClick={onDismissModal}>
              Dismiss
            </button>
          </>
        )}

      </Modal.Footer>
    </Modal>
  );
};
