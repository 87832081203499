import React, { useContext } from 'react';
import { TypedValue, U64Value } from '@multiversx/sdk-core/out';
import { ITokenPayment, IAddress, TokenPayment, Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scRequests';
import * as config from '../../../config';
import { sendAndSignTransactions } from './useTest';
import { Web3Context } from 'contexts/Web3Context';

//add auction id as parameter
export const useClaimReward = (auctionId: number) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3Context);

    const claimReward = async () => {

        const contract = await getSmartContractObj();
        const interaction = contract.methodsExplicit.claimAsset([new U64Value(auctionId)]);
        const displayInfo = {
            processingMessage: 'Processing claim reward transaction',
            errorMessage: 'An error has occurred while processing the transaction',
            successMessage: 'Claim reward successful'
        };
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [
                interaction
                    .withNonce(account.nonce)
                    .withValue(0)
                    .withGasLimit(10_000_000)
                    .withChainID(config.network.chainId)
                    .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return claimReward;
};

