import React, { useContext } from 'react';
import * as config from '../../config';
import axios from 'axios';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import { time } from 'console';


export const useGetTokenBalance = () => { //when there's no balance of token this function returns error: not found instead of 0
    const context = useContext(Web3LotteryContext);
    const getTokenBalance = async (address: string) => {

        if (address != '' && address !== undefined && address !== null && context.rewardTokenId !== undefined && context.rewardTokenId !== null) {
            const requestLink = `${config.network.apiAddress}/accounts/${address}/tokens/${context.rewardTokenId}`;

            // console.log('request link balance is', requestLink);
            // console.log('config network api address is', config.network.apiAddress);
            // console.log('address is', address);
            // console.log('token is', context.rewardTokenId);

            try {

                const { data } = await axios.get(
                    requestLink,
                    {
                        timeout: 20000
                    }
                );

                if (data.length === 0) {
                    throw 'missing balance';
                }

                return {
                    data: data === undefined ? '0' : data,
                    success: data !== undefined
                };
            } catch (err) {
                return {
                    data: {
                        balance: '0'
                    },
                    success: true
                };
            }
        }
    };
    return getTokenBalance;
};
