import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { IAddress, TokenPayment, Address, TokenIdentifierValue } from '@multiversx/sdk-core/out';
import * as config from '../../../config';
import { sendAndSignTransactions } from './useTest';
import { Web3Context } from 'contexts/Web3Context';


export const useCreateNewAuction = (nft: any, tokenId: string) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3Context);

    const createNewAuction = async () => {
   
        const nftId = nft.collection;
        const nonce = nft.nonce;
        const payment = TokenPayment.nonFungible(nftId, nonce);
        const displayInfo = {
            processingMessage: 'Processing create new auction transaction',
            errorMessage: 'An error has occurred while processing the transaction',
            successMessage: 'Create new auction successful'
        };
        const contract = await getSmartContractObj();
        let interaction = contract.methodsExplicit.createAuction();
        if(tokenId){
            interaction = contract.methodsExplicit.createAuction([new TokenIdentifierValue(tokenId)]);
        }
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [
                interaction
                    .withNonce(account.nonce)
                    .withValue(0)
                    .withSingleESDTNFTTransfer(payment, new Address(account.address))
                    .withGasLimit(50_000_000)
                    .withChainID(config.network.chainId)
                    .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return createNewAuction;
};


// export const useCreateNewAuction = (nft: any) => {
//     const { account } = useGetAccountInfo();

//     const createNewAuction = async () => {

//         const tokenId = nft.identifier;
//         const nonce = nft.nonce;
//         const payment = TokenPayment.nonFungible(tokenId, nonce);

//         const contract = await getSmartContractObj();
//         const interaction = contract.methodsExplicit.createAuction();
//         const { sessionId, error } = await sendTransactions({
//             transactions: [
//                 interaction
//                     .withNonce(account.nonce)
//                     .withValue(0)
//                     .withSingleESDTNFTTransfer(payment, new Address(account.address))
//                     .withGasLimit(5_000_000)
//                     .withChainID(config.network.chainId)
//                     .buildTransaction()
//             ]
//         });
//         // setSessionId(sessionId);
//         return sessionId;
//     };

//     return createNewAuction;
// };

