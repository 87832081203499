import React, { useState } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { useMysteryClaimTokens } from 'contexts/hooks/transactions/useMysteryClaimTokens';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import styles from '../MysteryBox/mystery.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { useMysteryAddKeys } from 'contexts/hooks/transactions/useMysteryAddKeys';
import { Col, Row } from 'react-bootstrap';

export const KeysContractDetails = () => {

  //add keys modal trigger
  const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);

  // chests amount
  const [keysAmount, setKeysAmount] = useState(0);
  const handleKeysAmountInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setKeysAmount(0);    
    }else{
    	const newKeysAmount = Number(event.target.value);
      setKeysAmount(newKeysAmount);
		}
  };

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Mystery Box - Keys Contract Config</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <strong>Contract</strong>
            <span>{config.mysteryKeysContractAddress}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-primary' onClick={useMysteryClaimTokens()}>
            Claim tokens
          </button>
          <button className='btn btn-primary' onClick={() => setShowModal(true)}>
            Add Keys
          </button>
        </div>        
      </div> 

      {/* Add keys modal*/}
        <Dialog
          open={showModal}
          onClose={handleCloseModal}
          fullWidth
          sx={{
            backgroundColor: 'transparent',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#180437',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(6, 11, 40, 0.74)',
            },
          }}
        > 
          <div className={styles.numbersModal}>       
            <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
              <span className='text-center'>Add keys</span>
            </DialogTitle>
            <IconButton
              size='small'
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <div className='p-2'>
                <div className='d-flex justify-content-between align-items-baseline'>
                  <p className="text-white mb-1 text-nowrap">
                    Keys amount to add:
                  </p>
                  <Input
                    value={keysAmount}
                    size="small"
                    placeholder=""
                    onChange={handleKeysAmountInput}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    disableUnderline
                    disabled={false}
                    className="text-white font-bold px-2 pt-1 b-r-xs me-2"
                    style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '16px'}}
                  />
                </div>
                <div className='mb-3 mt-3' style={{borderBottom: '1px dashed gray'}}/>
                <Button variant='contained' fullWidth  className='btn btn-primary mx-auto' onClick={useMysteryAddKeys(keysAmount)}>
                  Add Keys
                </Button>             

              </div>
            </DialogContent>
          </div>
        </Dialog>     
    </>
  );
};
