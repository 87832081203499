import React, { useContext } from 'react';
import { TypedValue, U64Value } from '@multiversx/sdk-core/out';
import {
  ITokenPayment,
  IAddress,
  TokenPayment,
  Address
} from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scRequests';
import * as config from '../../../config';
import { sendAndSignTransactions } from './useTest';
import { BigUIntValue } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { Web3Context } from 'contexts/Web3Context';

export const useChangeBidStep = (newBidStep: number) => {
  const { account } = useGetAccountInfo();
  const { sendAndSignTransactionsWrapped } = useContext(Web3Context);
  const bidStep = new BigNumber(newBidStep);
  const denomination = new BigNumber(10).pow(18);

  const changeBidStep = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.setFixedBidAmount([
      new BigUIntValue(bidStep.multipliedBy(denomination))
    ]);
    const displayInfo = {
      processingMessage: 'Processing change bid step amount transaction',
      errorMessage: 'An error has occurred while processing the transaction',
      successMessage: 'Change successful'
    };
    const { sessionId, error } = await sendAndSignTransactionsWrapped(
      [
        interaction
          .withNonce(account.nonce)
          .withValue(0)
          .withGasLimit(5_000_000)
          .withChainID(config.network.chainId)
          .buildTransaction()
      ],
      displayInfo
    );
    // setSessionId(sessionId);
    return sessionId;
  };

  return changeBidStep;
};
