import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, DialogContentText } from '@mui/material';
import React, { useContext } from 'react';
import { FixedLengthArray } from 'typesS';
import { PickNumberInput } from './PickNumberInput';
import styles from '../../lottery.module.scss';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
export interface PickTicketDialogProps {
  open: boolean
  onClose: () => void;
  save: (numbers: FixedLengthArray<[number, number, number]>) => void;
}

export const PickTicketDialog: React.FC<PickTicketDialogProps> = (props: PickTicketDialogProps) => {
  const [numbers, setNumbers] = React.useState<number[]>([]);
  const context = useContext(Web3LotteryContext);
  const maxNumber = context.maxNumber;

  const handleNumberClick = (value: number) => {
    if (numbers.includes(value)) {
      const newNumbers = [...numbers];
      newNumbers.splice(numbers.indexOf(value), 1);
      setNumbers(newNumbers);
    } else {
      setNumbers([...numbers, value]);
    }
  };

  const handleOnClose = () => {
    props.onClose();
    setNumbers([]);
  };

  const handleOnSave = () => {
    props.save([numbers[0], numbers[1], numbers[2]]);
    handleOnClose();
  };

  // const allPossibleNumbers = Array.from({ length: 80 }, (_, i) => i + 1);
  const allPossibleNumbers = Array.from({ length: maxNumber }, (_, i) => i + 1);
  const canSave = numbers.length === 3;

  interface PickNumberInputProps {
    value: number;
    isSelected: boolean;
    onClick: (number: number) => void;
    isClickable: boolean;
  }



  return (
    <Dialog open={props.open} onClose={handleOnClose} sx={{ borderRadius: '20px', scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f0f0f0' }}>
      <div className={styles.numbersModal} >
        <div className={styles.historyTitleTickets}>
          <div className='d-flex flex-row w-100 justify-content-between align-items-center me-2'>
            <h3 className={''}>Pick your numbers</h3>
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleOnClose}></button>
          </div>
        </div>
        <DialogContent>
          <div>
            {canSave
              ? (
                <>
                  <div className={styles.historyTime}>
                    You have picked 3 numbers.
                  </div>
                </>
              )
              : (
                <>
                  <div className={styles.historyTime}>
                    Pick {3 - numbers.length} more numbers.
                  </div>
                </>
              )}
          </div>
          <Stack direction="row" flexWrap="wrap" gap={2} mt={2}>
            {allPossibleNumbers.map((number, index) => (
              <PickNumberInput index={index} key={number} value={number} isSelected={numbers.includes(number)} onClick={handleNumberClick} isClickable={!canSave || numbers.includes(number)} />
            ))}
          </Stack>
        </DialogContent>
        <div className={styles.actions}>
          <a className={styles.buttonDark} onClick={handleOnClose} color="error">Cancel</a>
          {numbers.length === 3 && (
            <a className={styles.buttonLight} onClick={handleOnSave} >Confirm</a>
          )}

        </div>
      </div>
    </Dialog>
  );
};


