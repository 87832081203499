import React from 'react';
import faqs from './data';
import styles from './faq.module.scss';
import FaqElement from './FaqElement';
import { Layout } from 'components/Layout/Layout';

export const Faq = () => {
    const [showFaq, setShowFaq] = React.useState('');
    const props = { showFaq, setShowFaq, faqs };

    return (
        <>
            <Layout>
                <main>
                    <div className={styles.blur1}></div>
                    <div className={styles.title}>Frequently Asked Questions</div>
                    <div className={styles.text}>
                        Wondering how auctions tick? Dive into our FAQ section for down-to-earth explanations on how our auction house operates. From bidding tips to behind-the-scenes insights, we've got your curiosity covered.
                    </div>

                    <article className={styles.faqs}>
                        {faqs.map(({ title, description }, index) => {
                            const data = { title, description };
                            return <FaqElement {...props} {...data} key={`faq-no-${index}`} />;
                        })}
                    </article>
                </main>
            </Layout>
        </>
    );
};



