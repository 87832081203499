import React, { useContext, useState } from 'react';
import * as config from '../../config';
import { useClaimFunds } from 'contexts/hooks/transactions/useClaimFunds';
import axios from 'axios';
import { useEnableContract } from 'contexts/hooks/transactions/useEnableContract';
import { useDisableContract } from 'contexts/hooks/transactions/useDisableContract';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Web3Context } from 'contexts';
import BigNumber from 'bignumber.js';
import { contractAddress } from '../../config';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';

export const ContractDetails = () => {
  const { isLoggedIn } = useGetLoginInfo();
  const enableContract = useEnableContract();
  const disableContract = useDisableContract();

  const auctionSettings = useSelector(
    (state: RootState) => state.auctionInfo.auctionSettings
  );

  const { isInitialLoadDone } = useContext(Web3Context);
  const [scEgldBalance, setScEgldBalance] = useState<any>('');  

  const [tokenId, setTokenId] = useState('EGLD');
  const claimFunds = useClaimFunds(tokenId);
  const handleBidToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTokenId(e.target.value);
  };

  const getContractEgldBalance = async (address: string) => {
    try {
      const { data } = await axios.get(
        `${config.network.apiAddress}/address/${address}/balance`,
        {
          timeout: 10000
        }
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

  const [walletBalance, setWalletBalance] = useState<Array<{ identifier: string; balance: number }>>([]);
	const getWalletData = async () => {
		try {
			const response = await fetch(`${config.network.apiAddress}/accounts/${contractAddress}/tokens?size=2000`, {
				headers: {
					Accept: 'application/json',
				},
			});
			const json = await response.json();
			if (json) {
      const updatedWalletBalance = json.map((item: any) => {
        const balance = new BigNumber(item.balance);
        let newToken;

        if (item.identifier === 'USDC-c76f1f') {
          newToken = balance.dividedBy(new BigNumber(10).pow(6)).toNumber();
        } else {
          newToken = balance.dividedBy(new BigNumber(10).pow(18)).toNumber();
        }

        return {
          identifier: item.identifier,
          balance: newToken,
        };
      });

      setWalletBalance(updatedWalletBalance);
    }
		} catch (e) {
			console.error(e);
		}
	};


  React.useEffect(() => {
    getContractEgldBalance(config.contractAddress).then((value) => {
      // console.log('contract egld balance dispatched');
      setScEgldBalance(value.data.data.balance);
    });
  }, [scEgldBalance]);

  React.useEffect(() => {
		if(isLoggedIn){
			getWalletData();
		}
		const interval = window.setInterval(() => {
			if(isLoggedIn){
				getWalletData();
			}
		}, 5000);
		return () => window.clearInterval(interval);
	}, []);


  return auctionSettings.status === 'failed' ||
    auctionSettings.data === null ||
    auctionSettings.data === undefined 
    // ||
    // !isInitialLoadDone 
    ? 
    (
    <h1> Loading... </h1>
  ) : (
    <div className='card dapp-core-component__transactionsTable-styles__transactions-table' >
      <div className='card-header'>Contract details</div>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <strong>Contract address</strong>
          <span>{config.contractAddress}</span>
        </div>
        <div className='d-flex justify-content-between'>
          <strong>Contract funds</strong>
          <div>
            <span>{new BigNumber(
              scEgldBalance
            ).dividedBy(new BigNumber(10).pow(18)).toNumber()} EGLD</span><br/>
            {walletBalance.map((item, index) => (
              <span key={index}>{item.balance} {item.identifier}<br/></span>
            ))}
          </div>
        </div>
        <div className='d-flex justify-content-between mb-3 align-items-center'>
          <strong>Claim Token ID</strong>
          <div className="col-sm-10">
            <div className='d-flex flex-row'>
              <input type="text" className="form-control" id="inputText5" value={tokenId} onChange={e => handleBidToken(e)} />                  
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <strong>Contract state</strong>
          {auctionSettings.data.is_contract_enabled && (
            <span className='bg-success text-bold p-1'>ACTIVE</span>
          )}
          {!auctionSettings.data.is_contract_enabled && (
            <span className='bg-danger text-bold p-1'>INACTIVE</span>
          )}
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between'>
        <button className='btn btn-primary' onClick={claimFunds}>
          Claim funds
        </button>
        {auctionSettings.data.is_contract_enabled && (
          <button className='btn btn-primary' onClick={disableContract}>
            Disable contract
          </button>
        )}

        {!auctionSettings.data.is_contract_enabled && (
          <button className='btn btn-primary' onClick={enableContract}>
            Enable contract
          </button>
        )}
      </div>
    </div>
  );
};
