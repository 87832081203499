import React, { useEffect, useState } from 'react';
import {
    useGetAccountInfo,
    useGetLoginInfo
} from '@multiversx/sdk-dapp/hooks/account';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { adminAddress, network } from 'config';
import { Address, Transaction, TransactionWatcher, U64Value } from '@multiversx/sdk-core/out';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './hooks/transactions/useSendAndSign';
import * as scRequests from './scLotteryRequests';
import BigNumber from 'bignumber.js';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';

export interface TicketAndStake {
    userStake: BigNumber,
    first: number,
    second: number,
    third: number
}

export const initialTicketAndStake: TicketAndStake = {
    userStake: new BigNumber(0),
    first: 0,
    second: 0,
    third: 0
};


export interface IWeb3LotteryContext {
    isInitialLoadDone: boolean;
    currentLotteryId: number;
    lotteryHistory: any[],
    scEnabled: boolean,
    multiplier: number,
    maxTokenAmount: number,
    rewardTokenId: string,
    minWithdraw: number,
    maxNumber: number,
    numbersToExtract: number,
    startTime: number,
    userTickets: any[],
    pastWinningLotteries: any[],
    refreshState: () => void,
    sendAndSignTransactionsWrapped: (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType
    ) => Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }>,
    sendAndSignTransactionsWrappedTickets: (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
        lotteryId: number,
    ) => Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }>,
    sendAndSignTransactionsWrappedPastWinning: (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType
    ) => Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }>,
}

export const defaultLotteryState: IWeb3LotteryContext = {
    isInitialLoadDone: false,
    currentLotteryId: 0,
    lotteryHistory: [],
    scEnabled: false,
    multiplier: 0,
    maxTokenAmount: 0,
    rewardTokenId: '',
    minWithdraw: 0,
    maxNumber: 0,
    numbersToExtract: 0,
    startTime: 0,
    userTickets: [],
    pastWinningLotteries: [],
    refreshState: () => {
        /* do nothing */
    },
    sendAndSignTransactionsWrapped: async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType
    ) => {
        throw 'Not initialized';
    },
    sendAndSignTransactionsWrappedTickets: async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
        lotteryId: number
    ) => {
        throw 'Not initialized';
    },
    sendAndSignTransactionsWrappedPastWinning: async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
    ) => {
        throw 'Not initialized';
    }
};

export const Web3LotteryContext = React.createContext<IWeb3LotteryContext>(defaultLotteryState);

const watcher = new TransactionWatcher(
    new ApiNetworkProvider(network.apiAddress)
);

export const Web3LotteryProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        account: { address, balance }
    } = useGetAccountInfo();
    const { isLoggedIn } = useGetLoginInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [currentLotteryId, setCurrentLotteryId] = React.useState(0);
    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    const [lotteryHistory, setLotteryHistory] = useState([]);
    const [scEnabled, setScEnabled] = useState(false);
    const [multiplier, setMultiplier] = useState(0);
    const [maxTokenAmount, setMaxTokenAmount] = useState(0);
    const [rewardTokenId, setRewardTokenId] = useState('');
    const [minWithdraw, setMinWithdraw] = useState(0);
    const [maxNumber, setMaxNumber] = useState(0);
    const [numbersToExtract, setNumbersToExtract] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [userTickets, setUserTickets] = useState([[0, 0, 0]]);
    const [pastWinningLotteries, setPastWinningLoteries] = useState<[number, TicketAndStake][]>([[0, initialTicketAndStake]]);

    const refreshLotterySettings = async () => {
        const lotterySettings = await scRequests.getLotterySettings();

        // console.log('LOTTERY SETTINGS ARE:', lotterySettings);

        setScEnabled(lotterySettings.is_sc_enabled);
        setMultiplier(lotterySettings.multiplier);
        setMaxTokenAmount(lotterySettings.max_token_amount);
        setRewardTokenId(lotterySettings.reward_token_id);
        setMinWithdraw(lotterySettings.min_withdraw);
        setMaxNumber(lotterySettings.max_number);
        setNumbersToExtract(lotterySettings.numbers_to_extract);

    };

    const refreshUserTickets = async (currentLotteryIdSC: number) => {
        const userTicketsSC = await scRequests.getUserTickets(new Address(address), currentLotteryIdSC);
        setUserTickets(userTicketsSC);
    };

    const refreshLotteryHistory = async () => {
        //fetches all history (bad for query, might fail with out of gas)
        // const lotteryHistorySC = await scRequests.getLotteryHistory();

        //fetches last 10 lotteries
        if (currentLotteryId > 0) {
            if (currentLotteryId > 10) {
                const lotteryHistorySC = await scRequests.getLotteryHistoryByIdFrom(currentLotteryId - 10);
                setLotteryHistory(lotteryHistorySC);
            }else{
                const lotteryHistorySC = await scRequests.getLotteryHistoryByIdFrom(1);
                setLotteryHistory(lotteryHistorySC); 
            }
        }
    };

    const refreshPastWinningLotteries = async (address: Address) => {
        const pastWinningLotteriesSC = await scRequests.getPastWinningLotteries(new Address(address));
        setPastWinningLoteries(pastWinningLotteriesSC);
    };

    const refreshStartTime = async () => {
        const startTimeSC = await scRequests.getLotteryStartTime(currentLotteryId);
        setStartTime(startTimeSC);
    };


    const trackTransaction = async (transaction: Transaction) => {
        await watcher.awaitCompleted(transaction);
        refreshLotterySettings();
    };

    const sendAndSignTransactionsWrapped = async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
    ): Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }> => {
        const result = await sendAndSignTransactions(transactions, displayInfo);
        await trackTransaction(transactions[0]);
        return result;
    };

    const trackTransactionTickets = async (transaction: Transaction, lotteryId: number) => {
        await watcher.awaitCompleted(transaction);
        refreshUserTickets(lotteryId);
    };

    const trackTransactionPastWinning = async (transaction: Transaction) => {
        await watcher.awaitCompleted(transaction);
        refreshPastWinningLotteries(new Address(address));
    };

    const sendAndSignTransactionsWrappedTickets = async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
        lotteryId: number,
    ): Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }> => {
        const result = await sendAndSignTransactions(transactions, displayInfo);
        await trackTransactionTickets(transactions[0], lotteryId);
        return result;
    };

    const sendAndSignTransactionsWrappedPastWinning = async (
        transactions: Transaction[],
        displayInfo: TransactionsDisplayInfoType,
    ): Promise<{
        success: boolean;
        error: string;
        sessionId: string | null;
    }> => {
        const result = await sendAndSignTransactions(transactions, displayInfo);
        await trackTransactionPastWinning(transactions[0]);
        return result;
    };



    const refreshState = async () => {
        // console.log('refresh state fetch');

        const currentLotteryIdSC = await scRequests.getLastLotteryId();
        setCurrentLotteryId(currentLotteryIdSC ? currentLotteryIdSC.toNumber() : 0);

        // refreshUserTickets(currentLotteryIdSC);

        await refreshAccount();
    };

    useEffect(() => {
        if (currentLotteryId === 0) {
            return;
        }
        // console.log('refreshing data for lottery id =', currentLotteryId);
        const pending = [refreshLotteryHistory(), refreshStartTime(), refreshPastWinningLotteries(new Address(address))];
        Promise.all(pending).then(() => 
        console.log('')
        );
    }, [currentLotteryId]);


    useEffect(() => {
        refreshLotterySettings();
        refreshPastWinningLotteries(new Address(address));
        refreshLotteryHistory();
        // refreshStartTime();
        // refreshUserTickets(currentLotteryId);
    }, []);


    useEffect(() => {
        // console.log('refresh user tickets');
        refreshUserTickets(currentLotteryId);
    }, [currentLotteryId, hasPendingTransactions]);

    useEffect(() => {
        const interval = setInterval(
            () => {
                refreshState().then((_) => {
                    //
                });
            },
            6000
        );
        return () => clearInterval(interval);
    }, []);



    return (
        //return data from variables from sc
        <Web3LotteryContext.Provider
            value={{
                isInitialLoadDone,
                currentLotteryId,
                lotteryHistory,
                scEnabled,
                multiplier,
                maxTokenAmount,
                rewardTokenId,
                minWithdraw,
                maxNumber,
                numbersToExtract,
                startTime,
                userTickets,
                pastWinningLotteries,
                refreshState,
                sendAndSignTransactionsWrapped,
                sendAndSignTransactionsWrappedTickets,
                sendAndSignTransactionsWrappedPastWinning
            }}
        >
            {children}
        </Web3LotteryContext.Provider>
    );
};