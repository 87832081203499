import { useContext, useEffect, useState } from 'react';
import Timer from './components/Timer';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { FormatAmount } from '@multiversx/sdk-dapp/UI/FormatAmount/FormatAmount';
import styles from './testRoute.module.scss';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import Tilt from 'react-parallax-tilt';
import {
  faHeart,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { Web3Context } from 'contexts';
import { HistoryCarousel } from 'components/HistoryCarousel';
import { Layout } from 'components/Layout/Layout';
import Supa from 'database/SupabaseComponent';
import { getAuctionTokenId } from 'api/getAuctionInfo';
import * as config from '../../config';
import { getAuctionInfo } from 'api/getAuctionInfo';
import { AuctionInfoAndUrl } from 'store/types/auctionInfoAndUrl';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';

export const TestRoute = () => {
  const { platformUser, updateReferralPoints } = useContext(UserContext);
  const [timeUntilLastBid, setTimeUntilLastBid] = useState(0);
  const [bid, setBid] = useState(false);  
  const { account, address } = useGetAccountInfo();
  const context = useContext(Web3Context);
  const [tokenId, setTokenId] = useState('');
  const storedPoints = localStorage.getItem('storedPoints') ?? '0';
  const storedTimestamp = parseInt(localStorage.getItem('localTimestamp') ?? '0', 10);
  const { success } = useGetActiveTransactionsStatus();

  const [auctionInfo, setAuctionInfo] = useState<AuctionInfoAndUrl>({
    asset: undefined,
    starting_price: '0',
    last_bid_price: '0',
    last_bid_timestamp: 0,
    last_bid_block: 0,
    start_time: 0,
    end_time: 0,
    last_bidder: '',
    url: [],
    name: '',
    claimed: 0,
    esdt_token_id: '',
    esdt_token_name: '',
  }); 
  
  // get the auction token id
  const getAuctionInfoData = async () => {
    if(context.currentAuctionId){
      const newAuctionData = await getAuctionInfo(context.currentAuctionId);
      if(newAuctionData){
        setAuctionInfo(newAuctionData);
      }
    }
  };

  useEffect(() => {
		getAuctionInfoData();    
		const interval = window.setInterval(() => {
			getAuctionInfoData();
		}, 3000);
		return () => window.clearInterval(interval);
	}, [context]);

  const auctionSettings = useSelector(
    (state: RootState) => state.auctionInfo.auctionSettings
  );

  const { status } = useSelector(
    (state: RootState) => state.auctionInfo.auctionHistory
  );

  const { isInitialLoadDone } = useContext(Web3Context);
  const [nextPossibleBid, setNextPossibleBid] = useState<number>(0);

  useEffect(() => {
    let lastBidPrice;
    const bidStep = new BigNumber(auctionSettings.data?.bid_step ?? '0');

    if (auctionInfo?.last_bid_price == '0') {
      lastBidPrice = new BigNumber(auctionInfo?.starting_price ?? '0');
      setNextPossibleBid(
        lastBidPrice.dividedBy(new BigNumber(10).pow(18)).toNumber()
      );
    } else {
      lastBidPrice = new BigNumber(auctionInfo?.last_bid_price ?? '0');
      const nextBid = lastBidPrice.plus(bidStep);
      setNextPossibleBid(
        nextBid.dividedBy(new BigNumber(10).pow(18)).toNumber()
      );
    }

  }, [auctionSettings.data, nextPossibleBid]);

  useEffect(() => {
    const bidTimespan = auctionSettings.data?.bid_time_treshold_timestamp ?? 60;
    const last_bid_timestamp = getLastBidTimestamp();

    const expireTimestamp = last_bid_timestamp + bidTimespan;
    const now = new Date().getTime() / 1000;

    setTimeUntilLastBid(expireTimestamp - now);
  }, [auctionSettings.data]);

  const getLastBidTimestamp = () => {
    if (
      auctionInfo?.last_bid_timestamp !== undefined &&
      auctionInfo.last_bid_timestamp > 0
    ) {
      return auctionInfo.last_bid_timestamp;
    }

    if (
      auctionInfo?.start_time !== undefined &&
      auctionInfo.start_time > 0
    ) {
      return auctionInfo.start_time;
    }

    return 0;
  };

  // get the auction token id
  const getAuctionTokenData = async () => {
		const newTokendata = await getAuctionTokenId(context.currentAuctionId);
    if(newTokendata){
      setTokenId(newTokendata);
    }
    return newTokendata;
  };

  useEffect(() => {
		getAuctionTokenData();    
    const interval = window.setInterval(() => {
			getAuctionTokenData();
		}, 3000);
		return () => window.clearInterval(interval);
	}, [auctionInfo]);

  // get the token name for display
  const [walletBalance, setWalletBalance] = useState({
    name: '',
    identifier: '',
    balance: 0,
  });
	const getWalletData = async () => {    
    if(tokenId){      
      try {
        const response = await fetch(`${config.network.apiAddress}/accounts/${address}/tokens?size=2000`, {
          headers: {
            Accept: 'application/json',
          },
        });
        const json = await response.json();
        if (json) {
          const myTokenData = json.find((item: { identifier: string; balance: number; }) => item.identifier.trim() === tokenId.trim());
             
          if (myTokenData) {
            let newToken = 0;
            if (myTokenData.identifier === 'USDC-c76f1f') {
              newToken = new BigNumber(myTokenData.balance).dividedBy(new BigNumber(10).pow(6)).toNumber();
            } else {
              newToken = new BigNumber(myTokenData.balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
            }
    
            setWalletBalance({
              name: myTokenData.name,
              identifier: myTokenData.identifier,
              balance: newToken,
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
		if(address){
      getWalletData();
    }    
    const interval = window.setInterval(() => {
			if(address){
        getWalletData();
      }
		}, 5000);
		return () => window.clearInterval(interval);
	}, [context]);

  // points transaction tracker - place bid
  const pointsTransactionAPI = config.network.apiAddress + '/transactions?size=1&sender=' + address + '&receiver=' + config.contractAddress + '&function=placeBid&after=' + storedTimestamp;
  useEffect(() => {
		if(success && parseFloat(storedPoints) > 0) {
			const getTransactionData = async () => {
				try {
					const response = await fetch(pointsTransactionAPI);
					return await response.json();
				} catch (error) {
					console.error(error);
				}
			};
			getTransactionData().then(r => {        
				if(r && Object.keys(r).length) {         
          if(platformUser) {
            const points = parseFloat(storedPoints);
            updateReferralPoints(platformUser?.uid, points);
            toast.success(`Congratulations, you received ${points + platformUser.multiplier * points} points for your transaction`, {duration: 5000});
            localStorage.setItem('storedPoints', '0');
          }
				}				
			});
		}
	}, [success]);

  return auctionInfo === null ||
    // auctionInfo.data.last_bid_price === undefined ||
    auctionInfo === undefined ||
    auctionSettings.status === 'failed' ||
    auctionSettings.data === null ||
    status !== 'succeeded' ||
    !isInitialLoadDone ? (
    <Layout>
      <LoadingScreen />
    </Layout>
  ) : (
    <Layout>
      {/* <div className='test-route'> */}
      <div className={styles.UserDetailsComponent}>
        {/* <Supa /> */}
        <Tilt>
          <img
            src={auctionInfo?.url.toString()}
            className={styles.userImg}

          />
        </Tilt>
        <div className={styles.aside}>

          <div className={`${styles.title} text-center`}>
            {auctionInfo.asset?.name}
          </div>

          {/* <div className={styles.impressions}>
            <div>
              <div className={styles.text}>
                {auctionInfo.asset?.description}
              </div>
            </div>
          </div> */}

          {/* <div className='row mt-5'> */}
          <article className={`${styles.details} mt-2`}>
            <div className={styles.account}>
              {address && (
                <div className={`${styles.balance} d-flex align-items-center`}>
                  <div className={styles.balanceText}>Balance: </div>
                  <div className={`${styles.balanceNum} text-center`}>
                    {tokenId ? (
                      <span className='text-uppercase'>{walletBalance.balance.toFixed(2)} {walletBalance.name}</span>
                    ): (
                      <FormatAmount value={account.balance} />
                    )}
                  </div>
                </div>
              )}
              <div className={styles.bids}>

                {auctionInfo.last_bid_price === undefined ? (
                  <>
                    <div className={styles.bid}>
                      <div className={styles.balanceText}>Last bid</div>
                      <div className={styles.balanceNum}> 0 EGLD</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.bid}>
                      <div className={styles.balanceText}>Last bid</div>
                      <div className={styles.balanceNum}>
                        {' '}
                        {new BigNumber(auctionInfo.last_bid_price)
                          .dividedBy(new BigNumber(10).pow(18))
                          .toNumber()}{' '}
                        <span className='text-uppercase'>{tokenId ? tokenId.split('-')[0] : 'EGLD'}</span>                        
                      </div>
                    </div>
                  </>
                )}

                <div className={styles.bid}>
                  <div className={styles.balanceText}>Next bid</div>
                  <div className={styles.balanceNum}> 
                    {nextPossibleBid.valueOf()} 
                    <span className='text-uppercase'> {tokenId ? tokenId.split('-')[0] : 'EGLD'}</span> 
                    </div>
                </div>

                <div className={styles.bid}>
                  <div className={styles.balanceText}>Bid step</div>
                  <div className={styles.balanceNum}>
                    {' '}
                    {new BigNumber(auctionSettings.data.bid_step)
                      .dividedBy(new BigNumber(10).pow(18))
                      .toNumber()
                      .toLocaleString()}{' '}
                    <span className='text-uppercase'>{tokenId ? tokenId.split('-')[0] : 'EGLD'}</span>
                  </div>
                </div>

              </div>
            </div>
            <div className={styles.barImg} />


            <section className="" style={{ height: '100%' }}>
              <Timer
                duration={timeUntilLastBid}
                nextPossibleBid={new BigNumber(nextPossibleBid).toNumber()}
                tokenId={tokenId}
                auctionInfo={auctionInfo}
              />
            </section>
          </article>
          {/* </div> */}


        </div>
      </div>
      <div className={styles.RelatedProductsComponent}>
        <HistoryCarousel />
      </div>
      {/* </div> */}
    </Layout>
  );
};
