import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { fetchAuctionInfoData } from './slices/auctionInfo/auctionData';
import { fetchAuctionHistoryData } from './slices/auctionInfo/auctionHistory';
import { fetchAuctionSettingsData } from './slices/auctionInfo/auctionSettings';

export const useCustomDispatch = () => {
  const dispatch = useDispatch<AppDispatch>();

  const dispatchAuctionData = (auctionId: number) => {
    dispatch(fetchAuctionInfoData(auctionId));
    dispatch(fetchAuctionSettingsData());
  };

  const dispatchAuctionInfo = (auctionId: number) => {
    // console.log('fetch auction info dispatch');
    dispatch(fetchAuctionInfoData(auctionId));
  };

  const dispatchAuctionSettings = () => {
    // console.log('fetch auction settings dispatch');
    dispatch(fetchAuctionSettingsData());
  };

  const dispatchAuctionHistory = () => {
    dispatch(fetchAuctionHistoryData());
  };

  return {
    dispatchAuctionData,
    dispatchAuctionInfo,
    dispatchAuctionSettings,
    dispatchAuctionHistory
  };
};
