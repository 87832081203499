const faqs = [
    {title: 'What is xBid?', description: 'xBid is an online auction platform where participants can bid for various prizes. Participants can place price offers and compete to win the auction.'},
    {title: 'What does "starting price" mean in auctions?', description: 'The starting price represents the initial price at the beginning of the auction. In the case of xBid platform, the starting price will be 0.01 EGLD.'},
    {title: 'How does the price increment system work in auctions?', description: 'In the xBid platform, when you place a bid, you will have an option to automatically place a bid. Each accepted bid has a fixed increment of 0.001 EGLD compared to the previous bid. In other words, when you place a bid, the next accepted bid price will be automatically calculated and displayed for you. For example, if the last accepted price is 0.022 EGLD, the next accepted bid should be 0.023 EGLD.'},
    {title: 'What is the maximum amount the auction can reach?', description: 'In the auctions, the maximum accepted value is 0.5 or 1 EGLD (depending on the specifications mentioned in the smart contract).'},
    {title: 'What is the duration of the auction?', description: 'The auction does not have a pre-set end time. However, after each accepted bid, a timer will indicate a period of 60 seconds until the auction\'s completion. This allows participants time to decide whether they want to place a higher bid or not.'},
    {title: 'What happens if there are no bids placed during the 60-second period?', description: 'If no bids are placed during the 60-second period, the auction will end, and the last accepted bid will be declared as the winner.'},
    {title: 'What happens if someone bids more than the amount I bid?', description: 'If someone places a higher bid than yours, you will be outbid, and you will no longer be considered the highest bidder.'},
    {title: 'Is it possible to retrieve the bid amount if my bid is surpassed, and I decide not to continue bidding?', description: 'If you decide not to continue bidding and your bid is surpassed, you will not be able to retrieve the bid amount. It is important to consider this aspect before participating and ensure that you are prepared to take the risk of losing the bid amount.'},
  ];

  export default faqs;