import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useGetIsLoggedIn } from 'hooks';
import { routeNames } from 'routes';
import { UserContext } from 'contexts';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import toast, {Toaster} from 'react-hot-toast';

export const AuthRedirectWrapper = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useGetIsLoggedIn();
  const { platformUser, logout } = useContext(UserContext);
  const { address } = useGetAccountInfo();

  const handleLogout = async () => {
    try {      
      await logout();
      toast.success('You have been logged out', {duration: 3000});
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (platformUser && address) {
      if(address.toString() !== platformUser.walletAddress){        
        handleLogout();
      }
    }
    const interval = window.setInterval(() => {
			if (platformUser && address) {
        if(address.toString() !== platformUser.walletAddress){        
          handleLogout();        
        } 
      }
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);

  if (isLoggedIn && platformUser && address.toString() == platformUser.walletAddress) {
    return <Navigate to={routeNames.home} />;
  }

  return (
    <>
      {children}
      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </>
  );
};
