import { BigUIntValue, TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useTest';
import BigNumber from 'bignumber.js';
import { Web3Context } from 'contexts/Web3Context';
import { UserContext } from 'contexts';

export const usePlaceBid = (value: number, tokenId: string) => {
  const { platformUser } = useContext(UserContext);
  const { account } = useGetAccountInfo();
  const { sendAndSignTransactionsWrapped } = useContext(Web3Context);
  const currentTimestamp = new Date().getTime();
  const currentDateTimestamp = Math.floor(currentTimestamp / 1000);

  const displayInfo = {
    processingMessage: 'Processing place bid transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Bid placed successfully'
  };
  //   const valueBig = value * Math.pow(10, 18);
  const valueBig = new BigNumber(value).multipliedBy(new BigNumber(10).pow(18));
  const placeBid = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.placeBid();
    let transaction = interaction
      .withNonce(account.nonce)
      .withValue(new BigUIntValue(valueBig))
      .withGasLimit(15_000_000)
      .withChainID(network.chainId)
      .buildTransaction();
    if(tokenId){
      transaction = interaction
      .withNonce(account.nonce)
      .withValue(0)
      .withGasLimit(15_000_000)
      .withChainID(network.chainId)
      .withSingleESDTTransfer(
        TokenTransfer.fungibleFromAmount(tokenId, value, 18)
      )
      .buildTransaction();
    }

    if(platformUser) {
      localStorage.setItem('localTimestamp', currentDateTimestamp.toString());      
      localStorage.setItem('storedPoints', value.toString());
    }
    const sessionId = await sendAndSignTransactionsWrapped(
      [transaction],
      displayInfo
    );
    // setSessionId(sessionId);
    return sessionId;
  };

  return placeBid;
};
