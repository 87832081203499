export interface AuctionSettings {
  is_auction_open: boolean;
  is_contract_enabled: boolean;
  max_bid_amount: string;
  bid_step: string;
  bid_time_treshold_timestamp: number;
}

export const DEFAULT_SETTINGS: AuctionSettings = {
  is_auction_open: false,
  is_contract_enabled: false,
  max_bid_amount: '0',
  bid_step: '0',
  bid_time_treshold_timestamp: 0
};
