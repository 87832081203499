import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { ITokenPayment, IAddress, TokenPayment, Address, TokenIdentifierValue } from '@multiversx/sdk-core/out';
import * as config from '../../../config';
import { sendAndSignTransactions } from './useTest';
import { Web3Context } from 'contexts/Web3Context';


export const useClaimFunds = (tokenId: string) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3Context);
    const displayInfo = {
        processingMessage: 'Processing claim funds transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Claim funds successful'
    };

    const claimFunds = async () => {
        const contract = await getSmartContractObj();
        const interaction = contract.methodsExplicit.withdrawFunds([new TokenIdentifierValue(tokenId)]);
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [interaction
                .withNonce(account.nonce)
                .withValue(0)
                .withGasLimit(5_000_000)
                .withChainID(config.network.chainId)
                .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return claimFunds;
};

// export const useClaimFunds = () => {
//     const { account } = useGetAccountInfo();

//     const claimFunds = async () => {

//         const contract = await getSmartContractObj();
//         const interaction = contract.methodsExplicit.withdrawFunds();
//         const { sessionId, error } = await sendTransactions({
//             transactions: [
//                 interaction
//                     .withNonce(account.nonce)
//                     .withValue(0)
//                     .withGasLimit(5_000_000)
//                     .withChainID(config.network.chainId)
//                     .buildTransaction()
//             ]
//         });
//         // setSessionId(sessionId);
//         return sessionId;
//     };

//     return claimFunds;
// };

