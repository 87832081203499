import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, CardContent, CardHeader, Divider, IconButton, Stack } from '@mui/material';
import { Ticket } from 'Ticket';
import { TicketCardNumber } from './TicketCardNumber';
import { PickTicketDialog } from './PickTicketDialog';
import { FixedLengthArray } from 'typesS';
import styles from '../../lottery.module.scss';
import { getCorrectIndex } from '../WinningNumbers/WinningNumbers';
import '../WinningNumbers/index.css';

export interface TicketCardProps {
  ticket: Ticket;
  onTicketChange: (ticket: Ticket) => void;
}

export const TicketCard: React.FC<TicketCardProps> = (props: TicketCardProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = (numbers: FixedLengthArray<[number, number, number]>) => {
    props.onTicketChange(new Ticket(numbers));
  };

  // console.log('TicketCard: ticket is', props.ticket.numbers);
  return (

    <div className=''>
      <div className="d-flex justify-content-between align-items-center">
        <Button size='large' className={`${styles.selectNumbersButton} ${styles.buttonDark} text-capitalize text-white`} onClick={handleClickOpen}>
          Select Numbers
        </Button>
      </div>      
      <PickTicketDialog open={openDialog} onClose={handleClose} save={handleSave} />
    </div>
  );
};
