import { U64Value } from '@multiversx/sdk-core/out';
import {
  Provider,
  getSmartContractObj,
  resultsParser
} from 'contexts/scRequests';
import { AuctionSettings, DEFAULT_SETTINGS } from 'store/types/auctionSettings';
import BigNumber from 'bignumber.js';

export const getAuctionSettings = async (): Promise<AuctionSettings> => {

  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getAuctionSettings();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    const parsed = convertApiResponseSet(value);
    if (parsed !== null) {
      // console.log('parsed auction settings', parsed);
      return parsed;
    }
  } else {
    // console.log('getAuctionSettings failed', parsedResponse);

  }

  return DEFAULT_SETTINGS;
  // return value;
};



const convertApiResponseSet = (
  scApiResponse: any,
): AuctionSettings | null => {
  if (scApiResponse === undefined) {
    return null;
  }

  return {
    is_auction_open: scApiResponse.is_auction_open,
    is_contract_enabled: scApiResponse.is_contract_enabled,
    max_bid_amount: scApiResponse.max_bid_amount.toString(),
    bid_step: scApiResponse.bid_step.toString(),
    bid_time_treshold_timestamp: scApiResponse.bid_time_treshold_timestamp.toNumber()
  };
};


export const getBidTimeThreshold = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getBidTimeThreshold();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return new BigNumber(0);
};

export const getFixedBidAmount = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getFixedBidAmount();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return new BigNumber(0);
};


export const getMaxBidAmount = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getMaxBidAmount();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return new BigNumber(0);
};

export const getIsContractEnabled = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getEnabled();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return false;
};

export const getIsAuctionOpen = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getIsAuctionOpen();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return false;
};
