import React, { useState, useEffect, useContext } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { Address } from '@multiversx/sdk-core/out';

import styles from '../../lottery.module.scss';

const Timer: React.FC<{ duration: number }> = ({
    duration
}) => {
    const [seconds, setSeconds] = useState(duration);
    const { address } = useGetAccountInfo();


    useEffect(() => {
        if (seconds > 0) {
            const intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [seconds]);

    const formatTimeVar = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(Math.trunc(seconds)).padStart(2, '0');

        return (
            <div className={styles.dates}>
                <div className={styles.dateRight}>
                    <div className={styles.dateTitle}>{formattedMinutes}</div>
                    <div className={styles.dateText}>MINUTES</div>
                </div>
                <div className={styles.dateRight}>
                    <div className={styles.dateTitle}>{formattedSeconds}</div>
                    <div className={styles.dateText}>SECONDS</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        setSeconds(duration);
    }, [duration]);

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(Math.trunc(seconds)).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        
            <div className='mb-3 w-100 d-flex'>{formatTimeVar(seconds)}</div>

       
    );

   
};

export default Timer;
