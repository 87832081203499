import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { ITokenPayment, IAddress, TokenPayment, Address } from '@multiversx/sdk-core/out';
import * as config from '../../../config';
import { sendAndSignTransactions } from './useTest';
import { Web3Context } from 'contexts/Web3Context';

export const useCloseAuction = () => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3Context);

    const closeAuction = async () => {

        const contract = await getSmartContractObj();
        const interaction = contract.methodsExplicit.closeCurrentAuctionOwner();
        const displayInfo = {
            processingMessage: 'Processing close auction transaction',
            errorMessage: 'An error has occurred while processing the transaction',
            successMessage: 'Close auction successful'
        };
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [
                interaction
                    .withNonce(account.nonce)
                    .withValue(0)
                    .withGasLimit(15_000_000)
                    .withChainID(config.network.chainId)
                    .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return closeAuction;
};

