import {  Struct, TokenTransfer, U64Value } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import { useContext } from 'react';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import { UserContext } from 'contexts';

export const useBuyBulkTickets = (tickets: Array<object>, bet: number, gas: number, handleCloseMB: any) => {
  const { platformUser } = useContext(UserContext);
  const { account } = useGetAccountInfo();
  const { sendAndSignTransactionsWrappedTickets, rewardTokenId, currentLotteryId } = useContext(Web3LotteryContext);
  const currentTimestamp = new Date().getTime();
  const currentDateTimestamp = Math.floor(currentTimestamp / 1000);


  const displayInfo = {
    processingMessage: 'Processing buy multiple tickets transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Tickets bought successfully'
  };

  const buyTicket = async () => {
    const contract = await getSmartContractObj();

    const interaction = contract.methods.buyBulkTicket(tickets);

    const identifier = rewardTokenId;
    const numDecimals = 18;

    let transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), numDecimals);

    if (rewardTokenId === 'USDC-c76f1f') {
      transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), 6);
    }

    const transaction = interaction
      .withNonce(account.nonce)
      .withSingleESDTTransfer(transfer)
      .withGasLimit(gas)
      .withChainID(network.chainId)
      .buildTransaction();

    if(platformUser) {
      localStorage.setItem('localTimestamp', currentDateTimestamp.toString());      
      localStorage.setItem('storedPoints', bet.toString());
    }
    const sessionId = await sendAndSignTransactionsWrappedTickets(
      [transaction],
      displayInfo,
      currentLotteryId
    );
    return sessionId;
  };

  handleCloseMB;
  return buyTicket;
};
