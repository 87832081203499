// slices/accountInfo/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import auctionDataReducer from './auctionData';
import auctionHistoryReducer from './auctionHistory';
import auctionSettingsReducer from './auctionSettings';

const accountInfoReducer = combineReducers({
  auctionData: auctionDataReducer,
  auctionHistory: auctionHistoryReducer,
  auctionSettings: auctionSettingsReducer
  // other subfeatures
});

export default accountInfoReducer;
