import { BigUIntValue, ITokenTransfer, TokenTransfer, U64Value } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import { sendAndSignTransactions } from './useSendAndSign';
import { FixedLengthArray } from 'typesS';

export const useClaimReward = (lotteryId: number) => {
    const { account } = useGetAccountInfo();
    const  {sendAndSignTransactionsWrappedPastWinning, rewardTokenId, currentLotteryId} = useContext(Web3LotteryContext);


    const displayInfo = {
        processingMessage: 'Processing claim reward transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Reward claimed successfully'
    };

    // const valueBig = new BigNumber(bet).multipliedBy(new BigNumber(10).pow(18));
    const claimReward = async () => {
        const contract = await getSmartContractObj();

        const interaction = contract.methodsExplicit.claimReward([new U64Value(lotteryId)]);
        
        const transaction = interaction
            .withNonce(account.nonce)
            .withGasLimit(50_000_000)
            .withChainID(network.chainId)
            .buildTransaction();

        const sessionId = await sendAndSignTransactionsWrappedPastWinning(
            [transaction],
            displayInfo,
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return claimReward;
};
