import React, {useEffect, useRef, useState, useContext} from 'react';
import {
  faShoppingCart,
	faDiceD20,
	faHammer,
	faQuestion,
	faSignIn,
  faSignOut,
	faUser,
	faGift,
	faChevronDown,
  faUserTie,
  faSquarePollHorizontal
} from '@fortawesome/free-solid-svg-icons';
import styles from '../layout.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {logout as walletlogout} from 'helpers';
import {useGetAccount, useGetIsLoggedIn, useGetAccountInfo} from 'hooks';
import {ReactComponent as IxBidLogo} from '../../../assets/img/logo.svg';
import * as config from '../../../config';
import {AiOutlineBars} from 'react-icons/ai';
import Fade from 'react-bootstrap/Fade';
import { Home } from '../../../pages/Home/Home';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import { UserContext } from 'contexts';
import { useTheme, useMediaQuery } from '@mui/material';
import { CloseButton } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import toast, {Toaster} from 'react-hot-toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import BigNumber from 'bignumber.js';
import Image from 'react-bootstrap/Image';
import XBidLogo from 'assets/img/logo_no_text.png';
import XPlayLogo from 'assets/img/xplay_logo.png';
import egldLogo from 'assets/img/multiversx-symbol.svg';
import { intlNumberFormat } from 'contexts';

export const Navbar = () => {
  const { platformUser, logout } = useContext(UserContext);
	const isLoggedIn = useGetIsLoggedIn();

	const logoStyles: React.CSSProperties = {
		width: '150px',
		height: '100px'
	};
	const logoStylesSmaller: React.CSSProperties = {
		width: '1px',
		height: '1px',
		marginRight: '0px',
		marginLeft: '0px',
		marginTop: '0px',
		marginBottom: '0px',
		opacity: '0'
	};


	const [openNav, setOpenNav] = useState(false);
	const ref = useRef(null);
  const buyRef = useRef<HTMLDivElement | null>(null);
	const {address} = useGetAccount();
  const { account } = useGetAccountInfo();
	const navigate = useNavigate();
	const handleWalletLogout = () => {
		walletlogout(`${window.location.origin}/`);
	};
  const handleLogout = async () => {
    try {      
      await logout();
      toast.success('You have been logged out', {duration: 3000});
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const [showBuyXBidOptions, setShowBuyXBidOptions] = useState(false);
  const handleBuyXBidOptionClick = (option: 'solana' | 'multiversx') => {
    if (option === 'solana') {
      window.open('https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6D1PzpkcAQEe8xojVt4DkVoFExnwDtfgoewsG8Htgmn2&fixed=in', '_blank');
    } else if (option === 'multiversx') {
      window.open('https://swap.onedex.app/swap?firstToken=EGLD&secondToken=XBID-c7e360', '_blank');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const buyElement = document.getElementById('buyElement');

      if (buyElement && !buyElement.contains(event.target as Node)) {
        setShowBuyXBidOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

	// admin dropdown
	const [showDropdown, setShowDropdown] = useState(false);
  const handleAdminClick = () => {
    setShowDropdown(!showDropdown);
  };

  //Tokens API
  const tokensAPI = `${config.network.apiAddress}/accounts/${address}/tokens?size=2000`;
  const [xBidBalance, setXBidBalance] = useState(0);
  const [xPlayBalance, setXPlayBalance] = useState(0);
  const [egldBalance, setEgldBalance] = useState(0);
  const getWalletData = async () => {
		try {
			const response = await fetch(tokensAPI, {
				headers: {
					Accept: 'application/json',
				},
			});
			const json = await response.json();
			if (json) {
				json.forEach((item: any) => {
					if (item.identifier === config.xBidToken) {
						setXBidBalance(new BigNumber(item.balance).dividedBy(new BigNumber(10).pow(18)).toNumber());
					}
					if (item.identifier === 'XPLAY-67c10d') {
						setXPlayBalance(new BigNumber(item.balance).dividedBy(new BigNumber(10).pow(18)).toNumber());
					}
				});
			}
      if(account.balance){
        setEgldBalance(new BigNumber(account.balance).dividedBy(new BigNumber(10).pow(18)).toNumber());
      }
		} catch (e) {
			console.error(e);
		}
  };

  useEffect(() => {
    if(address){
      getWalletData();
    }
  }, [address]);

  // My account drawer
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    if(address){
      getWalletData();
    }
    setOpen(newOpen);    
  };

  const [showUsername, setShowUsername] = useState(false);
  const toggleUsernameVisibility = () => {
    setShowUsername(!showUsername);
  };

  //Copy to Clipboard Utility
  function CopyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    toast.success('Successfully copied', {duration: 3000});    
  }
  
	return (
		<>
			<div className={styles.logo}>
				<IxBidLogo style={logoStylesSmaller}/>
			</div>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor='right'
        PaperProps={{
          sx: {
            backgroundColor: '#5724c3',
            width: isMdUp ? '22vw' : '100vw',
            height: isMdUp ? 'auto' : 'auto',
            maxHeight: '100vh',
            overflowY: 'auto',
            marginTop: isMdUp ? '5vh' : '15vh',
            marginRight: isMdUp ? '0' : '0',
            borderRadius: '10px'
          }
        }}        
      >
        <div className='p-3' style={{background: 'rgba(5,5,5, 0.9)', height: '100%'}}>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='text-white h5'>Account Info - {platformUser ? <span className='text-lexaloffle-green' style={{color: 'red'}}>Connected</span> : <span style={{color: 'red'}}>Not Connected</span>}</p>
            <IconButton
              size='small'
              aria-label='close'
              onClick={toggleDrawer(false)}
              sx={{
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='my-1' style={{borderBottom: '1px solid gray'}}/>

          <div className='d-flex justify-content-between align-items-baseline text-white mt-1'>
            <small>Username:</small>
            {platformUser ? (
              <div>
                {showUsername ? platformUser?.username : '••••••••'}
                <IconButton onClick={toggleUsernameVisibility}>
                  {showUsername ? <VisibilityOff style={{color: 'white'}} />: <Visibility style={{color: 'white'}} />}
                </IconButton>
              </div>
            ) : (
              <div>
              <IconButton onClick={toggleUsernameVisibility}>
                {showUsername ? <VisibilityOff style={{color: 'white'}} />: <Visibility style={{color: 'white'}} />}
              </IconButton>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '-10px'}}>
            <small>Display Name:</small>
            <small>{platformUser?.displayName}</small>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>Points:</small>
            <small>{platformUser?.monthlyPoints >= 0 ? intlNumberFormat(platformUser?.monthlyPoints, 'en-GB', 0, 0) : ''}</small>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>Level:</small>
            <small>{platformUser?.level}</small>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>Multiplier:</small>
            <small>{platformUser?.multiplier ? (platformUser?.multiplier) * 100 : 0}%</small>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>Full Details:</small>
            <small onClick={() => navigate('/my-account')} style={{cursor: 'pointer', textDecoration: 'underline'}}>Click here</small>
          </div>
          <Row>
            <Col xs={6} className='mt-2'>
              <Button
                component={Link}
                to="/unlock"
                fullWidth
                size='small'                  
                className={!platformUser ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}                
              >
                Login
              </Button>
            </Col>
            <Col xs={6} className='mt-2'>
              <Button
                fullWidth
                onClick={handleLogout}
                size='small'                  
                className={platformUser ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}                
              >
                Logout
              </Button>
            </Col>
          </Row>
          
          <p className='text-white h5 mt-5'>Wallet Info - {address ? <span className='text-lexaloffle-green' style={{color: 'red'}}>Connected</span> : <span style={{color: 'red'}}>Not Connected</span>}</p>
          <div className='my-1' style={{borderBottom: '1px solid gray'}}/>

          <div className='d-flex justify-content-between align-items-baseline text-white mt-1'>
            <small>Wallet Address:</small>
            {platformUser ? (
              <div>
                {platformUser?.walletAddress.slice(0, 5)} ... {platformUser?.walletAddress.slice(58, 62)}
                <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(platformUser?.walletAddress)}><FontAwesomeIcon icon={faCopy} /></span>
              </div>
            ):(
              <div></div>
            )}            
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>xBid:</small>
            <div>
              <small>{intlNumberFormat(xBidBalance, 'en-GB', 2, 2)}</small>
              <Image
                width={15}
                height={15}
                alt='15x15'
                src={XBidLogo}
                style={{ marginTop: '-3px', marginLeft: '6px' }}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>xPlay:</small>
            <div>
              <small>{intlNumberFormat(xPlayBalance, 'en-GB', 2, 2)}</small>
              <Image
                width={18}
                height={18}
                alt='18x18'
                src={XPlayLogo}
                style={{ marginTop: '-3px', marginLeft: '3px' }}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-baseline text-white' style={{marginTop: '1px'}}>
            <small>eGLD:</small>
            <div>
              <small>{intlNumberFormat(egldBalance, 'en-GB', 2, 2)}</small>
              <Image
                width={15}
                height={15}
                alt='15x15'
                src={egldLogo}
                style={{ marginTop: '-3px', marginLeft: '5px' }}
              />
            </div>
          </div>
          <Row className='mb-4'>
            <Col xs={6} className='mt-2'>
              <Button
                component={Link}
                to="/unlock"
                fullWidth
                size='small'                  
                className={!address ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}                
              >
                Connect
              </Button>
            </Col>
            <Col xs={6} className='mt-2'>
              <Button
                fullWidth
                onClick={handleWalletLogout}
                size='small'                  
                className={address ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}                
              >
                Disconnect
              </Button>
            </Col>
          </Row>
        </div>          
      </Drawer>

      {/*Desktop screens*/}
			<div className={styles.d_none}>
				<div className={styles.flex}>
					<div className={styles.logo}>
						<IxBidLogo style={logoStyles}/>						
					</div>
					<div className={styles.urls}>
            <Link
              to='/dashboard'
              className={styles.url}
            >
              <FontAwesomeIcon icon={faSquarePollHorizontal}/>
              <div className={window.location.pathname === '/dashboard' ? styles.urlText : ''}>
                Dashboard
              </div>
            </Link>
						<Link
							to='/auction'
							className={styles.url}
						>
							<FontAwesomeIcon icon={faHammer}/>
							<div className={window.location.pathname === '/auction' ? styles.urlText : ''}>
								Auction
							</div>
						</Link>
						<Link
							to='/lottery'
							className={styles.url}
						>
							<FontAwesomeIcon icon={faDiceD20}/>
							<div className={window.location.pathname === '/lottery' ? styles.urlText : ''}>
								Lottery
							</div>
						</Link>
						<Link
							to='/mystery-box'
							className={styles.url}
						>
							<FontAwesomeIcon icon={faGift}/>
							<div className={window.location.pathname === '/mystery-box' ? styles.urlText : ''}>
								Mystery Box
							</div>
						</Link>
						{address === config.adminAddress && (
							<div className={styles.adminContainer}>
								<div onClick={handleAdminClick} className={styles.url}>									
									<div className={styles.urlText}>
										<FontAwesomeIcon icon={faUser} className='me-2'/>
										Admin
									</div>
									<FontAwesomeIcon icon={faChevronDown} />
								</div>
								{showDropdown && (
									<div className={styles.dropdown}>
										<Link to='/admin-auction' className={styles.url}>
											- Admin Auction
										</Link>
										<Link to='/admin-lottery' className={styles.url}>
											- Admin Lottery
										</Link>
										<Link to='/admin-mystery' className={styles.url}>
											- Admin Mystery
										</Link>
                    <Link to='/admin-database' className={styles.url}>
											- Admin Database
										</Link>
									</div>
								)}
							</div>
						)}
            <div
              className={`${styles.url} ${styles.subOptionsContainer}`}
              style={{ position: 'relative'}}
              onClick={() => setShowBuyXBidOptions(!showBuyXBidOptions)}
              id={'buyElement'} ref={buyRef}
            >
              <div  className={styles.divSubOptions}>
                <FontAwesomeIcon icon={faShoppingCart} className={'me-2'} />
                Buy xBid
              </div>
              <Fade in={showBuyXBidOptions}>
                <div className={styles.subOptions}>
                  <div onClick={() => handleBuyXBidOptionClick('solana')} style={{whiteSpace: 'nowrap'}}>
                    {'◆'} Solana
                  </div>
                  <div onClick={() => handleBuyXBidOptionClick('multiversx')} style={{whiteSpace: 'nowrap'}}>
                    {'◆'} MultiversX
                  </div>
                </div>
              </Fade>
            </div>
					</div>

					<div className={styles.logout}>
            {isLoggedIn ? (
              <div onClick={() => setOpen(true)} className={styles.url}>
                <FontAwesomeIcon icon={faUserTie} />
                  My account
              </div>
            ):(
              <div onClick={() => navigate('/unlock')} className={styles.url}>
                <FontAwesomeIcon icon={faSignIn} />
                Log In
              </div>
            )}
          </div>
				</div>
			</div>

      {/*Mobile screens*/}
			<div className={`${styles.flex} ${styles.barIcon}`}>
				<div className={styles.logo}>
					<IxBidLogo style={logoStyles}/>
				</div>

				<div
					ref={ref}
					onClick={() => setOpenNav(!openNav)}
					className={styles.bar}
				>
					<AiOutlineBars/>

					<div className={`${styles.urls} ${openNav ? styles.show : ''}`}>
            <div className={styles.logout}>
              {isLoggedIn ? (
                <div onClick={() => setOpen(true)} className={styles.url}>
                  <FontAwesomeIcon icon={faUserTie} style={{minWidth: '15px'}} />
                  My account
                </div>
              ):(
                <div onClick={() => navigate('/unlock')} className={styles.url}>
                  <FontAwesomeIcon icon={faSignIn} style={{minWidth: '15px'}} />
                  Log In
                </div>
              )}
            </div>
            <Link
              to='/dashboard'
              style={{textDecoration: 'none', color: 'white'}}
              className={styles.url}
            >
              <FontAwesomeIcon icon={faSquarePollHorizontal} style={{minWidth: '15px'}}/>
              <div className={window.location.pathname === '/dashboard' ? styles.urlText : ''}>
                Dashboard
              </div>
            </Link>
						<Link
							to='/auction'
							style={{textDecoration: 'none', color: 'white'}}
							className={styles.url}
						>
							<FontAwesomeIcon icon={faHammer} style={{minWidth: '15px'}}/>
							<div className={window.location.pathname === '/auction' ? styles.urlText : ''}>
								Auction
							</div>
						</Link>
						<Link
							to='/lottery'
							style={{textDecoration: 'none', color: 'white'}}
							className={styles.url}
						>
							<FontAwesomeIcon icon={faDiceD20} style={{minWidth: '15px'}}/>
							<div className={window.location.pathname === '/lottery' ? styles.urlText : ''}>
								Lottery
							</div>
						</Link>
						<Link
							to='/mystery-box'
							style={{textDecoration: 'none', color: 'white'}}
							className={styles.url}
						>
							<FontAwesomeIcon icon={faGift} style={{minWidth: '15px'}}/>
							<div className={window.location.pathname === '/mystery-box' ? styles.urlText : ''}>
								Mystery Box
							</div>
						</Link>
            {address === config.adminAddress && (
              <Link
                to='/admin-auction'
                style={{textDecoration: 'none', color: 'white'}}
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser} style={{minWidth: '15px'}}/>
                <div className={window.location.pathname === '/admin-auction' ? styles.urlText : ''}>
                  Admin Auction
                </div>
              </Link>
            )}
						{address === config.adminAddress && (
              <Link
                to='/admin-lottery'
                style={{textDecoration: 'none', color: 'white'}}
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser} style={{minWidth: '15px'}}/>
                <div className={window.location.pathname === '/admin-lottery' ? styles.urlText : ''}>
                  Admin Lottery
                </div>
              </Link>
            )}
						{address === config.adminAddress && (
              <Link
                to='/admin-mystery'
                style={{textDecoration: 'none', color: 'white'}}
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser} style={{minWidth: '15px'}}/>
                <div className={window.location.pathname === '/admin-mystery' ? styles.urlText : ''}>
                  Admin Mystery
                </div>
              </Link>
            )}
            {address === config.adminAddress && (
              <Link
                to='/admin-database'
                style={{textDecoration: 'none', color: 'white'}}
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser} style={{minWidth: '15px'}}/>
                <div className={window.location.pathname === '/admin-database' ? styles.urlText : ''}>
                  Admin Database
                </div>
              </Link>
            )}
            <a
              className={styles.url}
              href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6D1PzpkcAQEe8xojVt4DkVoFExnwDtfgoewsG8Htgmn2&fixed=in"
						>
							<FontAwesomeIcon icon={faShoppingCart} style={{ minWidth: '15px' }}/>
							<span style={{whiteSpace: 'nowrap'}}>Buy xBid - Solana</span>
            </a>
            <a
              className={styles.url}
              href="https://swap.onedex.app/swap?firstToken=EGLD&secondToken=XBID-c7e360"
						>
              <FontAwesomeIcon icon={faShoppingCart} style={{minWidth: '15px'}}/>
							<span style={{whiteSpace: 'nowrap'}}>Buy xBid - MultiversX</span>
            </a>
					</div>
				</div>
			</div>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
		</>
	);
};