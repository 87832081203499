import React from 'react';
import { AuctionInfo } from 'store/types/auctionInfo';
import BigNumber from 'bignumber.js';
import { useClaimReward } from 'contexts/hooks/transactions/useClaimReward';
import { Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import styles from '../../pages/TestRoute/testRoute.module.scss';
import { AuctionInfoAndUrl } from 'store/types/auctionInfoAndUrl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';


export const PastAuction = ({ auction, auctionId }: { auction: AuctionInfoAndUrl, auctionId: number }) => {
  //put button function claimReward
  //show button only when specific asset is not in user's wallet
  const claimAsset = useClaimReward(auctionId);
  const { address } = useGetAccountInfo();
  const auctionInfo = useSelector(
    (state: RootState) => state.auctionInfo.auctionData
  );

  // console.log(auction);

  // console.log(auctionId + 1);
  // console.log(auction.claimed);

  return (
    <>
      {new Address(address).hex() === auction.last_bidder ? (

        <div className={`custom-card ${styles.border}`}>
          <div className='custom-card-overlay '>
            {/* <div className='d-flex flex-column justify-space-between'> */}
              <div className={styles.balanceText}>Past auction #{auctionId + 1}</div>
              <h3 className='mb-2'>{auction.name}</h3>
              <div className={styles.balanceText}>
                Sold for{' '}
              </div>
              <div className={styles.dateTitle}>
                {new BigNumber(auction.last_bid_price ?? 0)
                  .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
                  .toNumber()}{' '}
                {auction.esdt_token_id.split('-')[0]}
              </div>
              {/* <div className='mb-2'></div> */}
              {/* <div onClick={claimAsset} className={styles.bidBtn}>
              Claim
              </div> */}
              {auction.claimed !== 1 ? (
                <div onClick={claimAsset} className={styles.bidBtn}>
                  Claim
                </div>
              ) : (
                <div className={styles.balanceText}>
                  Claimed
                </div>
              )}
              <div className='mb-2'></div>
            {/* </div> */}
          </div>
          <img
            src={auction.url.toLocaleString()}
            alt='auction-asset-img'
            className='custom-card-img img-responsive'
          />
        </div>

      ) : (

        <div className={`custom-card ${styles.border}`}>
          <div className='custom-card-overlay'>
            {/* <span> Past auction id: {auctionId + 1} </span> */}
            <div className={styles.balanceText}>Past auction #{auctionId + 1}</div>
            <h3 className='mb-2'>{auction.name}</h3>
            <div className={styles.balanceText}>
              Sold for{' '}
            </div>
            <div className={styles.dateTitle}>
              {new BigNumber(auction.last_bid_price ?? 0)
                .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
                .toNumber()}{' '}
              {auction.esdt_token_id.split('-')[0]}
            </div>
          </div>
          <img
            src={auction.url.toLocaleString()}
            alt='auction-asset-img'
            className='custom-card-img img-responsive'
          />
        </div>
      )}


    </>
  );
};
