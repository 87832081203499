// MysteryBox.jsx
import React, { useEffect, useState, useContext } from 'react';
import { Layout } from 'components/Layout/Layout';
import chestImage from 'assets/img/mystery_room.jpg';
import keyImage from 'assets/img/mystery_key.png';
import tokenImage from 'assets/img/logo_no_text.png';
import chestLogo from 'assets/img/chest.png';
import esdtImage from 'assets/img/esdt.png';
import sftImage from 'assets/img/sft.png';
import nftImage from 'assets/img/nft.png';
import styles from './mystery.module.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import { useMysteryBuyKey } from 'contexts/hooks/transactions/useMysteryBuyKey';
import { useMysteryUnlockChest } from 'contexts/hooks/transactions/useMysteryUnlockChest';
import { xBidToken, mysteryKey } from 'config';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { StoneStakingCard } from './components/StoneStakingCard';
import * as scRequests from '../../contexts/scStakingStonesRequests';
import {Address, BigUIntValue} from '@multiversx/sdk-core/out';
import { useStakingStonesClaim } from 'contexts/hooks/transactions/useStakingStonesClaim';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';


const componentsProps = {
	tooltip: {
		sx: {
			maxWidth: '200px',
			backgroundColor: 'black',
			color: 'white',
			fontSize: '14px',
			fontWeight: '400',
			textAlign: 'center',
			borderRadius: '10px',
			padding: '10px',
			top: '-10px'
		},
	},
	arrow: {
		sx: {
			color: 'black',
		},
	},
	TransitionComponent: Fade,
};

export const MysteryBox = () => {
  const { platformUser, updateReferralPoints } = useContext(UserContext);
  const { address } = useGetAccountInfo();
  const { success } = useGetActiveTransactionsStatus();
  const currentTimestamp = new Date().getTime();
  const currentDateTimestamp = Math.floor(currentTimestamp / 1000);
  const storedReward = localStorage.getItem('storedReward') ?? 'no';
  const storedPoints = localStorage.getItem('storedPoints') ?? '0';

  const [crystalBalance, setCrystalBalance] = useState(0);
  const [amethystBalance, setAmethystBalance] = useState(0);
  const [sapphireBalance, setSapphireBalance] = useState(0);
  const [emeraldBalance, setEmeraldBalance] = useState(0);

  // get the keys balance
  const [keysBalance, setKeysBalance] = useState(0);
  const getKeysData = async () => {  
    try {
      const response = await fetch(`${config.network.apiAddress}/accounts/${address}/nfts?size=2000`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const json = await response.json();
      if (json) {        
        json.map((nft: any) => {
          if (nft.collection === mysteryKey) {
            setKeysBalance(nft.balance);
          }
          if(nft.identifier ===  config.crystalSFT){
            setCrystalBalance(nft.balance);
          }
          if(nft.identifier ===  config.amethystSFT){
            setAmethystBalance(nft.balance);
          }
          if(nft.identifier ===  config.sapphireSFT){
            setSapphireBalance(nft.balance);
          }
          if(nft.identifier ===  config.emeraldSFT){
            setEmeraldBalance(nft.balance);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };


  // get the tokens balance
  const [walletBalance, setWalletBalance] = useState(0);
	const getWalletData = async () => {  
    try {
      const response = await fetch(`${config.network.apiAddress}/accounts/${address}/tokens?size=2000`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const json = await response.json();
      if (json) {
        json.map((token: any) => {
          if (token.identifier === xBidToken) {
            const newToken = new BigNumber(token.balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
            setWalletBalance(newToken);
          }          
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
		if(address){
      getKeysData();
      getWalletData();
    }    
    const interval = window.setInterval(() => {
			if(address){
        getKeysData();
        getWalletData();
      }
		}, 3000);
		return () => window.clearInterval(interval);
	}, [address]);

  //buy keys modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // keys amount
  const [keysAmount, setKeysAmount] = useState(0);
  const handleKeysAmountInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setKeysAmount(0);		
    }else{
    	const newKeysAmount = Number(event.target.value);
      setKeysAmount(newKeysAmount);
		}
  };

  //buy keys function
  const buyKeys = useMysteryBuyKey(keysAmount);
  const handleBuyKeys = async () => {
    localStorage.setItem('storedReward', 'no');
    if(platformUser) {
      localStorage.setItem('localTimestamp', currentDateTimestamp.toString());
      const points = keysAmount * 100;
      localStorage.setItem('storedPoints', points.toString());
    }
    handleCloseModal();
    await buyKeys();    
  };

  //unlock chests modal
  const [openModalUC, setOpenModalUC] = useState(false);
  const handleOpenModalUC = () => {
    setOpenModalUC(true);
  };
  const handleCloseModalUC = () => {
    setOpenModalUC(false);
  };

  // chests amount
  const [chestsAmount, setChestsAmount] = useState(0);
  const handleChestsAmountInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setChestsAmount(0);		
    }else if(event.target.value > 50){
      setChestsAmount(50);
    }else{
    	const newChestsAmount = Number(event.target.value);
      setChestsAmount(newChestsAmount);
		}
  };

  const handleChestsMaxAmount = (event: any) => {
		if(keysBalance > 50){
      setChestsAmount(50);
    }else{
      setChestsAmount(keysBalance);
    }
  };

  //display all prizes modal
  const [openModalDAP, setOpenModalDAP] = useState(false);
  const handleOpenModalDAP = () => {
    setOpenModalDAP(true);
  };
  const handleCloseModalDAP = () => {
    setOpenModalDAP(false);
  };

  //unlock chest function
  const unlockChest = useMysteryUnlockChest(chestsAmount);
  const handleUnlockChest = async () => {
    localStorage.setItem('localTimestamp', currentDateTimestamp.toString());
    localStorage.setItem('storedReward', 'yes');
    await unlockChest();    
    handleCloseModalUC();
  };

  //transaction tracker
  const [showRewardModal, setShowRewardModal] = useState(false);
	const handleCloseRewardModal = () => setShowRewardModal(false);
  const randomQueryParam = Math.random();
  const [rewardsContent, setRewardContent] = useState(<></>);  
  const storedTimestamp = parseInt(localStorage.getItem('localTimestamp') ?? '0', 10);
	const transactionAPI = config.network.apiAddress + '/transactions?size=1&sender=' + address + '&receiver=' + config.mysteryPrizesContractAddress + '&function=buy&after=' + storedTimestamp + '&withOperations=true';

  useEffect(() => {
		if(success && storedReward === 'yes') {
			const getTransactionData = async () => {
				try {
					const response = await fetch(transactionAPI);
					return await response.json();
				} catch (error) {
					console.error(error);
				}
			};
			getTransactionData().then(r => {        
				if(r && Object.keys(r).length) {
          if(r[0].operations){
            const prizeList: string[] = [];
            r[0].operations.map((operation: any) => {
              if(operation.type !== 'log' && operation?.collection != 'KEY-ebe055'){
                const opValue = parseInt(new BigNumber(operation.value).shiftedBy(-18).toFixed());
                //console.log('operations: ' + JSON.stringify(operation, null, 2));
                switch(operation.name){
                  //esdts
                  case 'xBid':
                    if(opValue === 50) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/50_xbid.png?${randomQueryParam}`);
                    if(opValue === 100) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/100_xbid.png?${randomQueryParam}`);                   
                  break;
                  case 'XLauncher':
                    if(opValue === 50) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/50_xlh.png?${randomQueryParam}`);
                    if(opValue === 100) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/100_xlh.png?${randomQueryParam}`);
                    if(opValue === 200) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/200_xlh.png?${randomQueryParam}`);
                  break;
                  case 'xPlay':                    
                    if(opValue === 25000) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/25000_xplay.png?${randomQueryParam}`);
                    if(opValue === 50000) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/50000_xplay.png?${randomQueryParam}`);
                  break;
                  case 'GoldStandardCoin':
                    if(opValue === 0.1) prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/esdts/1_gsc.png?${randomQueryParam}`);
                  break;

                  //stones
                  case 'Amethyst':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/stones/amethyst.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Crystal':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/stones/crystal.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Emerald':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/stones/emerald.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Ruby':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/stones/ruby.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Sapphire':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/stones/sapphire.png?${randomQueryParam}`);
                    }
                  break;

                  //sfts
                  case 'xBidLottery':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/xbid_lottery.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Gold VestaX':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/vesta_gold.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Silver VestaX':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/vesta_silver.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Bronze VestaX':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/vesta_bronze.png?${randomQueryParam}`);
                    }
                  break;
                  case 'ParascoX':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/parascox.png?${randomQueryParam}`);
                    }
                  break;
                  case 'MidasX':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/sfts/midasx.png?${randomQueryParam}`);
                    }
                  break;
                  
                  //nfts
                  case 'X-Launcher Origins #713':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/nfts/xlh_platinum.png?${randomQueryParam}`);
                    }
                  break;
                  case 'X-Launcher Origins #2275':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/nfts/xlh_gold.png?${randomQueryParam}`);
                    }
                  break;
                  case 'X-Launcher Origins #4476':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/nfts/xlh_silver.png?${randomQueryParam}`);
                    }
                  break;

                  //pieces
                  case 'Piece 2/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/2.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 3/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/3.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 4/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/4.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 5/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/5.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 6/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/6.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 7/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/7.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 8/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/8.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 9/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/9.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Piece 10/10':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/10.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Final Piece 20k XBID':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/final_20000.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Final Piece 30k XBID':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/final_30000.png?${randomQueryParam}`);
                    }
                  break;
                  case 'Final Piece 50k XBID':
                    for(let i = 1; i <= operation.value; i++){
                      prizeList.push(`https://x-launcher.com/files/xBid/mystery_prizes/pieces/final_50000.png?${randomQueryParam}`);
                    }
                  break;
                }
              }
            });
            if(prizeList){
              const newContent = 
              <>
                <Row>                  
                  {prizeList.map((prize, index) => (
                      <Col xs={6} key={index}>
                          <div className='mt-4'>
                            <img className={styles.rewardImages} src={prize}/>
                          </div>
                      </Col>
                  ))}
                </Row>
              </>;
              
              setRewardContent(newContent);
              setShowRewardModal(true);
            }            
          }					
				}				
			});
		}
	}, [success]);

  //staking api
  const [userStakedData, setUserStakedData] = useState({
    crystal: 0,
    amethyst: 0,
    sapphire: 0,
    emerald: 0
  });
  const getUserStakedData = async () => {
    const stakeData = await scRequests.getUserStakedData(new Address(address));
    if (stakeData && stakeData.length) {
      let updatedStakedData = {
        crystal: 0,
        amethyst: 0,
        sapphire: 0,
        emerald: 0
      };

      stakeData.forEach((item: any) => {
        const nonce = item.nonce.toString();
        switch (nonce) {
          case '5':
            updatedStakedData = { ...updatedStakedData, crystal: item.balance };
            break;
          case '4':
            updatedStakedData = { ...updatedStakedData, amethyst: item.balance };
            break;
          case '3':
            updatedStakedData = { ...updatedStakedData, sapphire: item.balance };
            break;
          case '2':
            updatedStakedData = { ...updatedStakedData, emerald: item.balance };
            break;
          default:
            break;
        }
      });
      setUserStakedData(updatedStakedData);
      getUserRewardsData(updatedStakedData.crystal, updatedStakedData.amethyst, updatedStakedData.sapphire, updatedStakedData.emerald);
    }      
  };

  const [userRewardsData, setUserRewardsData] = useState({
    crystal: 0,
    amethyst: 0,
    sapphire: 0,
    emerald: 0
  });
  const getUserRewardsData = async (crystal: any, amethyst: any, sapphire: any, emerald: any) => {
    const rewardsData1 = await scRequests.getUserRewardsData(new Address(address), 5, crystal);
    const rewardsData2 = await scRequests.getUserRewardsData(new Address(address), 4, amethyst);
    const rewardsData3 = await scRequests.getUserRewardsData(new Address(address), 3, sapphire);
    const rewardsData4 = await scRequests.getUserRewardsData(new Address(address), 2, emerald);

    let updatedRewardsData = {
      crystal: 0,
      amethyst: 0,
      sapphire: 0,
      emerald: 0
    };
    if(rewardsData1){
      updatedRewardsData = { ...updatedRewardsData, crystal: parseFloat(new BigNumber(rewardsData1).shiftedBy(-18).toFixed(3))};
    }
    if(rewardsData2){
      updatedRewardsData = { ...updatedRewardsData, amethyst: parseFloat(new BigNumber(rewardsData2).shiftedBy(-18).toFixed(3))};
    }
    if(rewardsData3){
      updatedRewardsData = { ...updatedRewardsData, sapphire: parseFloat(new BigNumber(rewardsData3).shiftedBy(-18).toFixed(3))};
    }
    if(rewardsData4){
      updatedRewardsData = { ...updatedRewardsData, emerald: parseFloat(new BigNumber(rewardsData4).shiftedBy(-18).toFixed(3))};
    }
    setUserRewardsData(updatedRewardsData);
  };

  const [userTotalRewards, setUserTotalRewards] = useState(0);
  const getUserTotalRewardsData = async () => {
    const totalRewardsData = await scRequests.getUserTotalRewards(new Address(address));

    if(totalRewardsData) setUserTotalRewards(parseFloat(new BigNumber(totalRewardsData).shiftedBy(-18).toFixed(3)));
  };

  useEffect(() => {
		if(address){
      getUserStakedData();
      getUserTotalRewardsData();
    }    
    const interval = window.setInterval(() => {
			if(address){
        getUserStakedData();
        getUserTotalRewardsData();
      }
		}, 1000);
		return () => window.clearInterval(interval);
	}, [address]);

  // display or hide the staking area
  const [showStakingArea, setShowStakingArea] = useState(false);
  const handleShowStakingArea = () => {
    setShowStakingArea(!showStakingArea);
  };

  // claim function
  const claimFunction = useStakingStonesClaim();
  const handleClaimFunction = async () => {
    localStorage.setItem('storedReward', 'no');
    await claimFunction();    
  };

  // points transaction tracker
  const pointsTransactionAPI = config.network.apiAddress + '/transactions?size=1&sender=' + address + '&receiver=' + config.mysteryKeysContractAddress + '&function=buyItem&after=' + storedTimestamp;
  useEffect(() => {
		if(success && parseFloat(storedPoints) > 0) {
			const getTransactionData = async () => {
				try {
					const response = await fetch(pointsTransactionAPI);
					return await response.json();
				} catch (error) {
					console.error(error);
				}
			};
			getTransactionData().then(r => {        
				if(r && Object.keys(r).length) {         
          if(platformUser) {
            const points = parseFloat(storedPoints);
            updateReferralPoints(platformUser?.uid, points);
            toast.success(`Congratulations, you received ${points} points for your transaction`, {duration: 5000});
            localStorage.setItem('storedPoints', '0');
          }
				}				
			});
		}
	}, [success]);

  return (
    <Layout>
      <div className='container'>
        <div className={styles.headerBox}>
          <div className='mt-3'></div>
          <div className={styles.headerText}>
            <div className={styles.crumbElement}> Mystery Box</div>
          </div>
          <div className=''></div>
          <h3>Exciting prizes await</h3>
        </div>

        
        <div className={styles.headerBox2}>
          <Row style={{marginTop: '-10px'}}>            
            <Col xs={6} lg={4} className='mb-3'>
              <Button
                size='large'
                onClick={handleShowStakingArea}            
                className={`${styles.buttonLight} text-white font-bold`}
              >
                {showStakingArea ? 'Hide' : 'Show'} Staking Area
              </Button>
            </Col>
            {showStakingArea && (
              <Col xs={6} lg={{offset: 4, span: 4}} className='mb-3'>
                <Button
                  size='large'
                  onClick={handleClaimFunction}
                  className={address ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}
                >
                  Claim {userTotalRewards} xPlay
                </Button>
              </Col>
            )}
          </Row>          
        </div>  
        {showStakingArea &&
          <div className={styles.headerBox2}>
            <Row className='mt-2'>          
              <Col xs={12} lg={3} xl={3} className='mb-5'>
                <StoneStakingCard 
                  containerStyles={styles.stone1Container} 
                  color='#e9eeee' 
                  btnStyle={`btn-crystal text-black ${styles.stoneButton}`} 
                  title='CRYSTAL'
                  balance={crystalBalance}
                  nonce={parseInt(config.crystalSFTNonce)}
                  staked={userStakedData.crystal}
                  rewards={30}
                  totalRewards={userTotalRewards}
                />
              </Col>
              <Col xs={12} lg={3} xl={3} className='mb-5'>
                <StoneStakingCard 
                  containerStyles={styles.stone2Container} 
                  color='#7E57C2' 
                  btnStyle={`btn-deep-purple-400 ${styles.stoneButton}`}
                  title='AMETHYST'
                  balance={amethystBalance}
                  nonce={parseInt(config.amethystSFTNonce)}
                  staked={userStakedData.amethyst}
                  rewards={60}
                  totalRewards={userTotalRewards}
                />
              </Col>
              <Col xs={12} lg={3} xl={3} className='mb-5'>
                <StoneStakingCard 
                  containerStyles={styles.stone3Container} 
                  color='#1963F8'
                  btnStyle={`btn-royal-blue ${styles.stoneButton}`}
                  title='SAPPHIRE'
                  balance={sapphireBalance}
                  nonce={parseInt(config.sapphireSFTNonce)}
                  staked={userStakedData.sapphire}
                  rewards={120}
                  totalRewards={userTotalRewards}
                />
              </Col>
              <Col xs={12} lg={3} xl={3} className='mb-5'>
                <StoneStakingCard 
                  containerStyles={styles.stone4Container} 
                  color='#66BB6A'
                  btnStyle={`btn-green-400 ${styles.stoneButton}`}
                  title='EMERALD'
                  balance={emeraldBalance}
                  nonce={parseInt(config.emeraldSFTNonce)}
                  staked={userStakedData.emerald}
                  rewards={240}
                  totalRewards={userTotalRewards}
                />
              </Col>
            </Row>
          </div>
        }
      
        <div className={styles.headerBox2}>
          <Row className='mt-2'>            
            <Col xs={12} lg={8} className='mb-5'>
              <div className='text-center'>
                <div className={styles.infoContainer}>
                <div className={styles.moveEffect} style={{ position: 'relative' }}>
                  <img className={styles.mysteryImage} src={chestImage} alt="Mystery Box Image" />
                  {/* <p style={{ 
                    position: 'absolute', 
                    top: '0', 
                    left: '50%', 
                    transform: 'translateX(-50%)', 
                    margin: '0',
                    width: '100%',
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    padding: '20px',
                    fontSize: '30px'
                  }}>
                    Mystery Box is under mentenance
                  </p> */}
                </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mb-5'>
              <h3 className='text-center'>Keys</h3>
              <div className={`${styles.sectionContainer} mt-2`}>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Your keys:</span>
                  <span style={{marginBottom: '1px'}}> {keysBalance} <img className='' width={'45px'} src={keyImage} alt="Key Image" /></span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Key price:</span>
                  <span>100 xBid <img style={{marginTop: '-4px'}} width={'18px'} src={tokenImage} alt="Token Image" /></span>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                  <span>Wallet:</span>
                  <span>{walletBalance} xBid <img style={{marginTop: '-4px'}} width={'18px'} src={tokenImage} alt="Token Image" /></span>
                </div>
                <Button
                    size='small'
                    onClick={handleOpenModal}
                    fullWidth
                    className={address ? (`${styles.buttonLight}  mt-3 text-white`) : (`${styles.buttonLight} ${styles.disabled} mt-3`)}
                  >
                    Buy Keys
                </Button>  
              </div>

              <h3 className='text-center mt-5'>Chest</h3>
              <div className={`${styles.sectionContainer} mt-2`}>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Unlockable chests:</span>
                  <span style={{marginBottom: '1px'}}> {keysBalance} <img className='ms-1' style={{marginTop: '-5px'}} width={'30px'} src={chestLogo} alt="Chest Image" /></span>
                </div>
                <Button
                    size='small'
                    onClick={handleOpenModalUC}
                    fullWidth
                    className={address ? (`${styles.buttonLight}  mt-3 text-white`) : (`${styles.buttonLight} ${styles.disabled} mt-3`)}
                  >
                    Unlock Chests
                </Button> 
              </div>

              <h3 className='text-center mt-5'>Prizes</h3>
              <div className={`${styles.sectionContainer} mt-2`}>
                <Row>
                  <Col xs={4}>
                    <img
                      src={esdtImage}
                      className={styles.rewardTypeImage}
                    />
                  </Col>
                  <Col xs={4}>
                    <img
                      src={sftImage}
                      className={styles.rewardTypeImage}
                    />
                  </Col>
                  <Col xs={4}>
                    <img
                      src={nftImage}
                      className={styles.rewardTypeImage}
                    />
                  </Col>
                </Row>
                <Button
                    size='small'
                    onClick={handleOpenModalDAP}
                    fullWidth
                    className={`${styles.buttonDark} mt-3 text-white`}
                  >
                    What can you win?
                </Button>  
              </div>
            </Col>            
          </Row>
        </div>

        {/* Buy keys modal */}
        <Dialog open={openModal} onClose={handleCloseModal} fullWidth sx={{backgroundColor: 'transparent', scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f0f0f0'}}> 
          <div className={styles.numbersModal}>       
            <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
              Mystery Box Keys
            </DialogTitle>
            <IconButton
              size='small'
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Row>
                <Col xs={12} lg={12}>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className="text-white mb-1 text-nowrap">
                      Keys amount:
                    </p>
                    <div className='d-flex justify-content-end'>
                      <Input
                        value={keysAmount}
                        size="small"
                        placeholder=""
                        onChange={handleKeysAmountInput}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        disableUnderline
                        disabled={false}
                        className="text-white font-bold px-2 pt-1 b-r-xs me-2"
                        style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '16px'}}
                      />
                      <Tooltip key='showInfo' title={`You can buy up to ${(walletBalance / 100).toFixed(0)} keys based on your current balance`} arrow placement='bottom' componentsProps={componentsProps}>
                        <Button 
                          className={`${styles.buttonDark} text-white`} 
                          onClick={() => setKeysAmount(parseInt((walletBalance / 100).toFixed(0)))}
                          style={{width: 'auto'}}
                        >
                          Max
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                  <div className='d-flex justify-content-between align-items-center'>
                    <p>Total Price:</p>
                    <p>{keysAmount * 100} xBid <img style={{marginTop: '-4px'}} width={'18px'} src={tokenImage} alt="Token Image" /></p>
                  </div>
                  <div className='mb-3' style={{borderBottom: '1px dashed gray'}}/>
                  <Row>
                    <Col xs={12}>
                      <Button className={`${styles.buttonDark} text-white`} onClick={handleBuyKeys}>Buy Keys</Button>
                    </Col>
                  </Row>
                </Col>                
              </Row>            
            </DialogContent>
          </div>
        </Dialog>

        {/* Unlock chest modal */}
        <Dialog open={openModalUC} onClose={handleCloseModalUC} fullWidth sx={{backgroundColor: 'transparent', scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f0f0f0'}}> 
          <div className={styles.numbersModal}>       
            <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
              Mystery Box Unlock
            </DialogTitle>
            <IconButton
              size='small'
              aria-label='close'
              onClick={handleCloseModalUC}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Row>
                <Col xs={12} lg={12}>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className="text-white mb-1 text-nowrap">
                      Chests amount to unlock:
                    </p>
                    <div className='d-flex justify-content-end'>
                      <Input
                        value={chestsAmount}
                        size="small"
                        placeholder=""
                        onChange={handleChestsAmountInput}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        disableUnderline
                        disabled={false}
                        className="text-white font-bold px-2 pt-1 b-r-xs me-2"
                        style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '16px'}}
                      />
                      <Tooltip key='showInfo' title='You can unlock up to 50 chests per transaction' arrow placement='bottom' componentsProps={componentsProps}>
                        <Button 
                          className={`${styles.buttonDark} text-white`} 
                          onClick={handleChestsMaxAmount}
                          style={{width: 'auto'}}
                        >
                          Max
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                  <div className='d-flex justify-content-between align-items-center'>
                    <p>Total Used Keys:</p>
                    <p style={{marginBottom: '1px'}}> {chestsAmount} <img className='' width={'45px'} src={keyImage} alt="Key Image" /></p>
                  </div>
                  <div className='mb-3' style={{borderBottom: '1px dashed gray'}}/>
                  <Row>
                    <Col xs={12}>
                      <Button className={`${styles.buttonDark} text-white`} onClick={handleUnlockChest}>Unlock Chests</Button>
                    </Col>
                  </Row>
                </Col>                
              </Row>            
            </DialogContent>
          </div>
        </Dialog>

        {/* Display all prizes modal */}
        <Dialog open={openModalDAP} onClose={handleCloseModalDAP} fullWidth 
          sx={{
            backgroundColor: 'transparent',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#180437',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(6, 11, 40, 0.74)',
            },
          }}
        > 
          <div className={styles.numbersModal}>       
            <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
              Mystery Box Rewards List
            </DialogTitle>
            <IconButton
              size='small'
              aria-label='close'
              onClick={handleCloseModalDAP}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <div className='p-2'>
                <h5 className='text-primary'>XPLAY</h5> 
                <Row>
                  <Col xs={12}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>25,000 xPlay</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>50,000 xPlay</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>XBID</h5> 
                <Row>
                  <Col xs={12}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>50 xBid</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>100 xBid</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>XLH</h5> 
                <Row>
                  <Col xs={12}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>50 XLH</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>100 XLH</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>200 XLH</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>GOLDSTANDARDCOIN</h5> 
                <Row>
                  <Col xs={12}>
                    <p className='mb-0'><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>0.1 GSC</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>XBIDPUZZLE</h5> 
                <Row>
                  <Col xs={12} lg={6} className='mt-1'>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 2/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 3/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 4/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 5/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 6/10</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 7/10</p>
                  </Col>
                  <Col xs={12} lg={6} className='mt-1'>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 8/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 9/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Piece 10/10</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Final Piece 20,000</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Final Piece 30,000</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Final Piece 50,000</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>X-LAUNCHER ORIGINS COLLECTION</h5> 
                <Row>
                  <Col xs={12}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>X-Launcher Origins Silver</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>X-Launcher Origins Gold</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>X-Launcher Origins Platinum</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>VESTAX & XBID LOTTERY</h5> 
                <Row>
                  <Col xs={6}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Bronze VestaX</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Silver VestaX</p>
                  </Col>
                  <Col xs={6}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Gold VestaX</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>xBidLottery</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>PARASCOX & MIDASX</h5> 
                <Row>
                  <Col xs={12}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>ParascoX</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>MidasX</p>
                  </Col>
                </Row>

                <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>
                <h5 className='text-primary'>STONES</h5> 
                <Row>
                  <Col xs={6}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Amethyst</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Crystal</p>
                    <p className='mb-0' style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Sapphire</p>
                  </Col>
                  <Col xs={6}>
                    <p><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Emerald</p>
                    <p style={{marginTop: '-10px'}}><ArrowRightIcon style={{fontSize: '20px', marginTop: '-3px', marginLeft: '-8px'}}/>Ruby</p>
                  </Col>
                </Row>
              </div>              
            </DialogContent>
          </div>
        </Dialog>
        
        {/* Display won rewards*/}
        <Dialog
          open={showRewardModal}
          onClose={handleCloseRewardModal}
          fullWidth
          sx={{
            backgroundColor: 'transparent',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#180437',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(6, 11, 40, 0.74)',
            },
          }}
        > 
          <div className={styles.numbersModal}>       
            <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
              <span className='text-center'>CONGRATULATIONS</span>
            </DialogTitle>
            <IconButton
              size='small'
              aria-label='close'
              onClick={handleCloseRewardModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'red'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <div className='p-2'>
                {rewardsContent}
              </div>
            </DialogContent>
          </div>
        </Dialog>
      </div>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </Layout>
  );
};
