import React, { Ref, RefObject, useContext, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { LoadingScreen } from 'components/LoadingScreen';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';

import { PastLottery } from '../HistoryCarousel/PastAuction';
import { WinningNumbers } from '../WinningNumbers/WinningNumbers';
import styles from '../../lottery.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';




export const HistoryCarousel = () => {

  const context = useContext(Web3LotteryContext);
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current index

  const handlePrevClick = () => {
    setCurrentIndex(Math.max(currentIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex(Math.min(currentIndex + 1, context.lotteryHistory.length - 1));
  };

  // console.log('suntem in history carousel');
  // console.log('lottery history Carousel is', context.lotteryHistory);

  // console.log('LOTTERY HISTORY[0]', context.lotteryHistory[0]);

  return context.lotteryHistory === null || context.lotteryHistory === undefined || context.lotteryHistory[0] === undefined ? (
    <LoadingScreen />
  ) : (
    <>
      {context.lotteryHistory.length === 0 ? (
        // <h1 className={styles.historyTime}>No past lotteries to show</h1>
        <> </>
      ) : (

        <>
          <div className={styles.carouselWrapper}>
            <div id="bootstrapCarousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {context.lotteryHistory.slice().reverse().map((lottery, i) => (
                  <div key={`lottery-history-${i}`} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                    {/* <PastLottery lottery={lottery} lotteryId={context.lotteryHistory.length - i - 1} endTime={lottery.field1} /> */}
                    <PastLottery lottery={lottery} lotteryId={context.currentLotteryId - i - 2} endTime={lottery.field1} />
                  </div>
                ))}
              </div>

            </div>
            <div className={styles.carouselNavigation}>
              <div className={`${styles.carouselArrowLeft} carousel-control-prev  `} data-bs-target="#bootstrapCarousel" data-bs-slide="prev">
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <div className={`${styles.carouselArrowRight} carousel-control-next `} data-bs-target="#bootstrapCarousel" data-bs-slide="next">
                <FontAwesomeIcon icon={faChevronRight} />

              </div>
            </div>
          </div>
        </>
      )}
    </>
  );


};
{/* <button className="carousel-control-prev" type="button" data-bs-target="#bootstrapCarousel" data-bs-slide="prev"> */ }
{/* <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span> */}
{/* </button> */ }
{/* <button className='carousel-control-next' type="button" data-bs-target="#bootstrapCarousel" data-bs-slide="next"> */ }
{/* <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span> */}
{/* </button> */ }

{/* <Carousel
              autoPlay={false}
              interval={3000}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              animationHandler={'slide'}
              swipeable={true}
              showIndicators={false}
            >
              {context.lotteryHistory.map((lottery, i) => (
                <PastLottery lottery={lottery} key={`lottery-history-${i}`} lotteryId={i} />
              ))}
            </Carousel> */}