import React, { useEffect, useState } from 'react';
import { Layout } from 'components/Layout/Layout';
import chestImage from 'assets/img/mystery_room.jpg';
import keyImage from 'assets/img/mystery_key.png';
import tokenImage from 'assets/img/logo_no_text.png';
import chestLogo from 'assets/img/chest.png';
import esdtImage from 'assets/img/esdt.png';
import sftImage from 'assets/img/sft.png';
import nftImage from 'assets/img/nft.png';
import styles from '../mystery.module.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import { useStakingStonesStake } from 'contexts/hooks/transactions/useStakingStonesStake';
import { useStakingStonesUnstake } from 'contexts/hooks/transactions/useStakingStonesUnstake';
import { xBidToken, mysteryKey } from 'config';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import * as config from '../../../config';
import BigNumber from 'bignumber.js';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const componentsProps = {
	tooltip: {
		sx: {
			maxWidth: '200px',
			backgroundColor: 'black',
			color: 'white',
			fontSize: '14px',
			fontWeight: '400',
			textAlign: 'center',
			borderRadius: '10px',
			padding: '10px',
			top: '-10px'
		},
	},
	arrow: {
		sx: {
			color: 'black',
		},
	},
	TransitionComponent: Fade,
};

export const StoneStakingCard = (
  {containerStyles, color, btnStyle, title, balance, nonce, staked, rewards, totalRewards} : 
  {containerStyles: any, color: string, btnStyle: string, title: string, balance: number, nonce: number, staked: number, rewards: number, totalRewards: number}) => {

  const { address } = useGetAccountInfo();

  // stake modal
  const [openModalS, setOpenModalS] = useState(false);
  const handleOpenModalS = () => {
    setOpenModalS(true);
  };
  const handleCloseModalS = () => {
    setOpenModalS(false);
  };

  // stake amount
  const [stakeAmount, setStakeAmount] = useState(0);
  const handleStakeAmountInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setStakeAmount(0);		
    }else if(event.target.value > balance){
      setStakeAmount(balance);
    }else{
    	const newAmount = Number(event.target.value);
      setStakeAmount(newAmount);
		}
  };

  // unstake modal
  const [openModalU, setOpenModalU] = useState(false);
  const handleOpenModalU = () => {
    setOpenModalU(true);
  };
  const handleCloseModalU = () => {
    setOpenModalU(false);
  };

  // unstake amount
  const [unstakeAmount, setUnstakeAmount] = useState(0);
  const handleUnstakeAmountInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setUnstakeAmount(0);		
    }else if(event.target.value > staked){
      if(staked < 1){
        setUnstakeAmount(1);
      }else{
        setUnstakeAmount(staked);
      }      
    }else{
    	const newAmount = Number(event.target.value);
      setUnstakeAmount(newAmount);
		}
  };

  // stake function
  const stakeFunction = useStakingStonesStake(config.stonesSFT, nonce, stakeAmount);
  const handleStakeFunction = async () => {
    localStorage.setItem('storedReward', 'no');
    handleCloseModalS();
    await stakeFunction();
  };

  // unstake function
  const unstakeFunction = useStakingStonesUnstake(config.stonesSFT, nonce, unstakeAmount);
  const handleUnstakeFunction = async () => {
    localStorage.setItem('storedReward', 'no');
    handleCloseModalU();
    await unstakeFunction();    
  };

  return (
    <>
      <div className={`${containerStyles} font-bold`}>
        <div className={styles.stoneCard}>
          <p className='text-center font-size-xl'>{title}</p>
          <div className='d-flex justify-content-between mt-5'>
            <p>Staked</p>
            <p>{staked.toString()} SFT{staked == 1 ? '' : 'S'}</p>
          </div>
          <div className='d-flex justify-content-between' style={{marginTop: '-10px'}}>
            <p>Rewards / Day</p>
            <p>{rewards.toString()} xPlay</p>
          </div>
          <div className='d-flex justify-content-between' style={{marginTop: '-10px'}}>
            <p>SFTS Balance</p>
            <p>{balance.toString()} SFT{balance == 1 ? '' : 'S'}</p>
          </div>

          <div className='my-3' style={{borderBottom: `1px solid ${color}`}}/>
          <Row>
            <Col xs={6}>
              <Tooltip key='ShowInfoS' title='You need to claim before you can stake if you have more than 50 xPlay reward' arrow placement='bottom' componentsProps={componentsProps}>
                <div>
                  <Button className={btnStyle} fullWidth onClick={handleOpenModalS} disabled={address && totalRewards < 50 ? false : true}>
                    Stake
                  </Button>
                </div>
              </Tooltip>
            </Col>
            <Col xs={6}>
              <Tooltip key='ShowInfoU' title='You need to claim before you can unstake if you have more than 50 xPlay reward' arrow placement='bottom' componentsProps={componentsProps}>
                <div>
                  <Button className={btnStyle} fullWidth onClick={handleOpenModalU} disabled={address && totalRewards < 50 ? false : true}>
                    Unstake
                  </Button>
                </div>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>

      {/* Stake modal */}
      <Dialog open={openModalS} onClose={handleCloseModalS} fullWidth sx={{backgroundColor: 'transparent', scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f0f0f0'}}> 
        <div className={styles.numbersModal}>       
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
            STAKE STONE
          </DialogTitle>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleCloseModalS}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'red'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Row>
              <Col xs={12} lg={12}>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className="text-white mb-1 text-nowrap">
                    Stake Amount:
                  </p>
                  <div className='d-flex justify-content-end'>
                    <Input
                      value={stakeAmount}
                      size="small"
                      placeholder=""
                      onChange={handleStakeAmountInput}
                      onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                          }
                      }}
                      disableUnderline
                      disabled={false}
                      className="text-white font-bold px-2 pt-1 b-r-xs me-2"
                      style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '16px'}}
                    />
                    <Tooltip key='showInfo' title={`You can stake up to ${balance} stone(s) based on your balance`} arrow placement='bottom' componentsProps={componentsProps}>
                      <Button 
                        className={`${styles.buttonDark} text-white`} 
                        onClick={() => setStakeAmount(balance)}
                        style={{width: 'auto'}}
                      >
                        Max
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className='mb-3' style={{borderBottom: '1px dashed gray'}}/>
                <Row>
                  <Col xs={12}>
                    <Button
                      className={address && stakeAmount ? (`${styles.buttonLight}  mt-3 text-white`) : (`${styles.buttonLight} ${styles.disabled} mt-3`)}
                      onClick={handleStakeFunction}
                    >
                      Stake
                    </Button>
                  </Col>
                </Row>
              </Col>                
            </Row>            
          </DialogContent>
        </div>
      </Dialog>

      {/* Unstake modal */}
      <Dialog open={openModalU} onClose={handleCloseModalU} fullWidth sx={{backgroundColor: 'transparent', scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f0f0f0'}}> 
        <div className={styles.numbersModal}>       
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
            UNSTAKE STONE
          </DialogTitle>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleCloseModalU}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'red'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Row>
              <Col xs={12} lg={12}>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className="text-white mb-1 text-nowrap">
                    Unstake Amount:
                  </p>
                  <div className='d-flex justify-content-end'>
                    <Input
                      value={unstakeAmount}
                      size="small"
                      placeholder=""
                      onChange={handleUnstakeAmountInput}
                      onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                          }
                      }}
                      disableUnderline
                      disabled={false}
                      className="text-white font-bold px-2 pt-1 b-r-xs me-2"
                      style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '16px'}}
                    />
                    <Tooltip key='showInfo' title={`You can unstake up to ${staked} stone(s) based on your address`} arrow placement='bottom' componentsProps={componentsProps}>
                      <Button 
                        className={`${styles.buttonDark} text-white`} 
                        onClick={() => setUnstakeAmount(staked)}
                        style={{width: 'auto'}}
                      >
                        Max
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className='mb-3' style={{borderBottom: '1px dashed gray'}}/>
                <Row>
                  <Col xs={12}>
                    <Button 
                      className={address && unstakeAmount ? (`${styles.buttonLight}  mt-3 text-white`) : (`${styles.buttonLight} ${styles.disabled} mt-3`)}
                      onClick={handleUnstakeFunction}
                    >
                      Unstake
                    </Button>
                  </Col>
                </Row>
              </Col>                
            </Row>            
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};