import React, { useContext, useState } from 'react';
import * as config from '../../config';
// import { useClaimFunds } from 'contexts/hooks/transactions/useClaimFunds';
import axios from 'axios';
import { useEnableContract } from 'contexts/hooks/transactions/useEnableLotteryContract';
import { useDisableContract } from 'contexts/hooks/transactions/useDisableLotteryContract';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { Web3LotteryContext } from '../../contexts/Web3LotteryContext';
import { useGetTokenBalance } from 'contexts/api/useGetTokenBalance';
import { useWithdrawTokens } from 'contexts/hooks/transactions/useWithdrawTokens';
import { useWithdrawSpecificToken } from 'contexts/hooks/transactions/useWithdrawSpecificToken';

export const ContractDetails = () => {
  const [scEgldBalance, setScEgldBalance] = useState<any>('');
  const [scTokenBalance, setScTokenBalance] = useState('');
  const [tokenIdWithdraw, setTokenIdWithdraw] = useState('');

  const context = useContext(Web3LotteryContext);
  const enableContract = useEnableContract();
  const disableContract = useDisableContract();
  const getTokenBalance = useGetTokenBalance();
  const withdrawTokens = useWithdrawTokens();
  const withdrawSpecificToken = useWithdrawSpecificToken(tokenIdWithdraw);

  const handleNewTokenIdWithdraw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTokenIdWithdraw(e.target.value);
  };

  const getContractEgldBalance = async (address: string) => {
    try {
      const { data } = await axios.get(
        `${config.network.apiAddress}/address/${address}/balance`,
        {
          timeout: 10000
        }
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };


  React.useEffect(() => {
    getContractEgldBalance(config.lotteryContractAddress).then((value) => {
      if (value.data !== undefined) {
        setScEgldBalance(value.data.data.balance);
      }
    });

    getTokenBalance(config.lotteryContractAddress).then((data) => {
      if (data?.data !== undefined && data !== undefined && data?.data?.balance !== undefined) {
        setScTokenBalance(data?.data?.balance.toString());
      }
    });
  }, []);

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Contract details</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <strong>Contract address</strong>
            <span>{config.lotteryContractAddress}</span>
          </div>
          <div className='d-flex justify-content-between'>
            <strong>Contract funds</strong>
            <span>{new BigNumber(
              scEgldBalance
            ).dividedBy(new BigNumber(10).pow(18)).toNumber()} EGLD</span>
          </div>
          <div className='d-flex justify-content-between'>
            <strong>Contract tokens</strong>
            <span>{new BigNumber(
              scTokenBalance
            ).dividedBy(new BigNumber(10).pow(18)).toNumber()}{' '}{context.rewardTokenId}</span>
          </div>
          <div className='d-flex justify-content-between'>
            <strong>Contract state</strong>
            {context.scEnabled && (
              <span className='bg-success text-bold p-1'>ACTIVE</span>
            )}
            {!context.scEnabled && (
              <span className='bg-danger text-bold p-1'>INACTIVE</span>
            )}
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between'>
          {context.scEnabled && (
            <button className='btn btn-primary' onClick={disableContract}>
              Disable contract
            </button>
          )}

          {!context.scEnabled && (
            <button className='btn btn-primary' onClick={enableContract}>
              Enable contract
            </button>
          )}

          <button className='btn btn-success' onClick={withdrawTokens}>
            Withdraw {context.rewardTokenId}
          </button>
        </div>
      </div>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mt-2 mb-2'>
        <div className='card-header'>Withdraw other tokens</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <strong>Token id</strong>
            <div className="col-sm-10">
              <div className='d-flex flex-row'>
                <input type="text" className="form-control" id="inputText2" onChange={e => handleNewTokenIdWithdraw(e)} />
                {/* <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={withdrawSpecificToken}>Set</button> */}

              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-success' onClick={withdrawSpecificToken}>
            Withdraw token
          </button>
        </div>
      </div>
    </>
  );
};
