import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Ticket } from 'Ticket';
import { TicketCardNumber } from '../TicketCard/TicketCardNumber';
import BigNumber from 'bignumber.js';
import styles from '../../lottery.module.scss';
import './index.css';



export const LastWinningNumbers: React.FC<{ numbers: number[] }> = ({
  numbers
}) => {
  return (
    <div className='d-flex text-center mt-3'>
      <div className='col-12'>
        <div className="row row-cols-12 g-2" style={{ gap: '10px', justifyContent: 'space-between' }}>
          {numbers?.map((number, index) => (
            <div className="col-2 d-flex justify-content-center align-items-center mt-2" key={number}>
              <div className={styles.numbers}>
                {index % 2 === 0 ? (
                  <p className={`text-white font-bold ${getCorrectIndex(index)}`} >{number}</p>
                ) : (
                  <p className={`text-white font-bold ${getCorrectIndex(index)}`}>{number}</p>
                )}
              </div>
            </div>
          ))}
      </div>
      </div>
    </div>
  );
};

export const getCorrectIndex = (currentIndex: number) => {

  const sphereIndex = currentIndex % 6;
  const sphereClass = `back${sphereIndex + 1}`;
  return sphereClass;
};

